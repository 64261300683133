import React from 'react';
import { clientInfoDataType } from './PropDefinitions';
import WrappedTabbedBody from '../containers/WrappedTabbedBody';
import WrappedNonAuthenticatedTabbedBody from '../containers/WrappedNonAuthenticatedTabbedBody';

/**
 * Builds the body of the display page.
 * @param {*} bodyData The data to populate the page with.
 * @return {jsx} The body of the display page.
 */
const DisplayPageBody = ({ bodyData }) => {
  if (sessionStorage.getItem('cachedAccessToken')?.toLowerCase().includes('paf')) {
    return (<WrappedTabbedBody bodyData={bodyData} />);
  }

  return (<WrappedNonAuthenticatedTabbedBody bodyData={bodyData} />);
};
DisplayPageBody.propTypes = {
  bodyData: clientInfoDataType.isRequired
};

export default DisplayPageBody;
