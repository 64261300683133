import React from 'react';
import * as PropTypes from 'prop-types';
import WrappedNofGridBody from '../containers/WrappedNofGridBody';
import { nofListingsDataType } from './PropDefinitions';

/**
 * Builds the grid of the Nof page.
 * @param classes {Object} contains a collection of class styles to use.
 * @param nofPostingRows {Array} The array to populate the grid with.
 * @param introMessage {string} The message to display before the table body.
 * @return {undefined} This function does not return a value.
 */
const NofGrid = ({
  nofPostingRows, introMessage,
}) => {
  
  return (
    <>
      <p style={{ color: '#6B6B6B' }}>{introMessage}</p>
      <WrappedNofGridBody rows={nofPostingRows} />
    </>
  );
};
NofGrid.propTypes = {
  nofPostingRows: nofListingsDataType.isRequired,
  introMessage: PropTypes.string,
};

export default (NofGrid);
