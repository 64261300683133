/**
 * The relative endpoint that will provide current authentication info.
 * @type {string}
 */
const authEndpoint = '/.auth/me';

/**
 * The relative endpoint that handles authenticating a user.
 * @type {string}
 */
export const loginEndpoint = '/.auth/login/aad?post_login_redirect_url=%2F';

/**
 * The label used for the expiration time of a JWT.
 * @type {string}
 */
const expirationClaimLabel = 'exp';

/**
 * The cached authentication token.
 * @type {string}
 */
let cachedIdToken;

/**
 * The date/time (in milliseconds) when the current cached token expires.
 * @type {Number}
 */
let cacheExpiration;

/**
 * Gets the value for a single claim from a collection.
 * @param {{
 *   typ: string,
 *   val: string,
 * }[]} claimList A collection of claims.
 * @param {string} typ The claim name to get a value for.
 * @returns {string | null} The value for a single claim.
 */
const getClaim = (claimList, typ) => {
  if (!claimList) {
    return null;
  }

  const foundClaim = claimList.find((claim) => claim.typ === typ);

  if (!foundClaim) {
    return null;
  }

  return foundClaim.val;
};

/**
 * Processes a successful response.
 * @param {Response} response A successful response.
 * @param {number} currentTime The time (in milliseconds) to compare cache expiration against.
 * @param {function} resolve Function to call on success.
 * @param {function} reject Function to call on error or failure.
 * @return {undefined} This function does not return a value.
 */
const handleSuccess = (response, currentTime, resolve, reject) => {
  response.json().then((responseJson) => {
    const { id_token: idToken, user_claims: claims } = responseJson[0];
    cachedIdToken = idToken;
    // JWT expiration time is stored in epoch format to the nearest second.
    // This will fill in the time for milliseconds for proper comparison.
    cacheExpiration = Number(getClaim(claims, expirationClaimLabel)) * 1000;
    if (currentTime < cacheExpiration) {
      resolve(cachedIdToken);
    } else {
      window.location.href = loginEndpoint;
    }
  }).catch((error) => reject(error));
};

/**
 * Gets an authentication token.
 * @param {Function} resolve The function to supply the authentication token to when available.
 * @param {Function} reject The function to call in the event of an error.
 * @return {undefined} This function does not return a value.
 */
const requestIdToken = (resolve, reject) => {
  const currentTime = Date.now();

  if (cachedIdToken && currentTime < cacheExpiration) {
    resolve(cachedIdToken);
    return;
  }

  fetch(authEndpoint)
    .then((response) => {
      if (response.ok) {
        handleSuccess(response, currentTime, resolve, reject);
      } else {
        window.location.href = loginEndpoint;
      }
    })
    .catch(() => {
      window.location.href = loginEndpoint;
    });
};

export default requestIdToken;
