import { connect } from 'react-redux';
import { requestAuthenticatedFile } from '../actions';
import DocumentList from '../components/DocumentList';
import { requestFile } from '../actions/index';

/**
 * Identifier for downloads initiated by this control.
 * @type {string}
 */
const downloadCategory = 'paf-attachment';

/**
 * Gets the props to supply to the wrapped component.
 * @param {{
 *  fileDownload: {
 *    category: string,
 *    fileId: string,
 *    filename: string,
 *   }
 *  }} state The current state.
 * @param {{fileMetadata: Array}} ownProps The properties that were submitted to this component.
 * @returns {{
 *  fileMetadata: Array,
 *  activeDownloadList: Array,
 * }} The props to supply to the wrapped component.
 */
const mapStateToProps = (state, ownProps) => ({
  fileMetadata: ownProps.fileMetadata,
  activeDownloadList: state.fileDownload.filter((download) => download.category === downloadCategory),
});

/**
 * Gets the functions to supply to the wrapped component.
 * @param {function} dispatch A function that wraps another function in Redux state flow.
 * @returns {{
 *  requestFile: (function(*=, *=, *=, *=): *)
 * }} The functions to supply to the wrapped component
 */
const mapDispatchToProps = (dispatch) => ({
  requestFile: (fileUrl, pafId, filename, requiresAuth) => {
    if (requiresAuth) {
      return dispatch(requestAuthenticatedFile(fileUrl, downloadCategory, pafId, filename));
    }
    return dispatch(requestFile(fileUrl, downloadCategory, pafId, filename));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DocumentList);
