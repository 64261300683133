import { connect } from 'react-redux';
import App from '../components/App';

/**
 * Gets the props to send to wrapped component.
 * @param {{ clientData: { loggedIn: boolean }}} state The current state.
 * @returns {{loggedIn: boolean}} The props to send to the wrapped  component.
 */
const mapStateToProps = (state) => ({
  loggedIn: state.clientData.loggedIn,
});

export default connect(
  mapStateToProps,
)(App);
