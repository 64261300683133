import React from 'react';
import { Link, withStyles } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import WrappedPafGridBody from '../containers/WrappedPafGridBody';
import DownloadIcon from './DownloadIcon';
import { pafListingsDataType } from './PropDefinitions';

/**
 * Style options to use with this component.
 * @param {Object} theme Theme styles to apply.
 * @returns {{
 *  head: {height: number},
 *  table: {
 *    overflowX: string,
 *    width: string,
 *    marginTop: number
 *  },
 *  introParagraph: {
 *    color: string,
 *  },
 *  link: {
 *    color: string,
 *    fontSize: string,
 *  }
 * }}
 */
const styles = (theme) => ({
  table: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  head: {
    height: 40,
  },
  introParagraph: {
    color: '#6B6B6B',
  },
  link: {
    color: '#6B6B6B',
    fontSize: '18px',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '10px',
    alignItems: 'center'
  },
});

/**
 * Checks if a given file is among the active downloads.
 * @param {(string | null)} id The ID associated with the download.
 * @param {string} fileName The filename associated with the download.
 * @param {{
 *   category: string,
 *   fileId: string,
 *   fileName: string,
 * }[]} activeDownloadList A collection of the current file downloads.
 * @returns {boolean} True if the file is currently downloading, otherwise false.
 */
// eslint-disable-next-line arrow-body-style
const isDownloading = (id, fileName, activeDownloadList) => {
  // eslint-disable-next-line arrow-body-style
  return activeDownloadList.some((file) => {
    return file.fileId === id && file.fileName === fileName;
  });
};

/**
 * Builds the grid of the LCA page.
 * @param {Object} classes contains a collection of class styles to use.
 * @param {Array} pafListings The array to populate the grid with.
 * @param {string} introMessage Message to display at the beginning of the section.
 * @param {string} memoDownloadLink URL to download the PAF memo.
 * @param {{
 *  entryId: string,
 *  fileName: string
 * }[]} activeDownloadList List of files currently being downloaded.
 * @param {(function(*=, *=, *=): *)} requestFile A function to download a file.
 * @return {jsx} The grid representing the table of PAF data.
 */
const PafGrid = ({
  classes, pafListings, introMessage, memoDownloadLink, activeDownloadList, requestFile, errorMessage
}) => {
  const {
    introParagraph: introParagraphStyle, link: linkStyle,
  } = classes;

  if (pafListings == null) {
    introMessage = errorMessage ? '' : 'Loading...';
    pafListings = [];
  }

  const defaultName = 'memo.pdf';

  let onClickHandler = requestFile;
  // eslint-disable-next-line arrow-body-style
  const memoDownloading = isDownloading(null, defaultName, activeDownloadList);

  if (memoDownloading) {
    // Do nothing on click if download is active.
    onClickHandler = () => {};
  }

  return (
    <>
      <p className={introParagraphStyle}>{introMessage}</p>      
      <div>
        <Link
          component="button"
          onClick={() => onClickHandler(memoDownloadLink, null, defaultName)}
          className={linkStyle}
        >
          Download company memo
          &nbsp;&nbsp;
          <DownloadIcon downloading={memoDownloading} />
        </Link>
      </div>
      <WrappedPafGridBody pafListings={pafListings} />
    </>
  );
};
PafGrid.propTypes = {
  classes: PropTypes.shape({
    table: PropTypes.string,
    head: PropTypes.string,
    introParagraph: PropTypes.string,
    link: PropTypes.string,
  }).isRequired,
  pafListings: pafListingsDataType.isRequired,
  introMessage: PropTypes.string.isRequired,
  memoDownloadLink: PropTypes.string.isRequired,
  activeDownloadList: PropTypes.arrayOf(PropTypes.object).isRequired,
  requestFile: PropTypes.func.isRequired,
  errorMessage: PropTypes.string
};

export default withStyles(styles)(PafGrid);
