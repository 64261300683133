import downloadHandler from '../api/downloadHandler';

/**
 * Removes characters from a string that are not permitted.
 * @param accessCode {string} A string of user input.
 * @return {string} A string with only permitted characters included.
 */
export const sanitizeInput = (accessCode) => accessCode?.replace(/[^0-9a-z-]/gi, '');

/**
 * Determines and initiates any additional steps needed to perform a request.
 * @param {string} token The client token being used.
 * @param {Function} authPerformed A function to call if the additional authentication was needed.
 * @param {Function} noAuthPerformed A function to call if there was no additional authentication.
 */
export const handleAuthRequirement = (token, authPerformed, noAuthPerformed) => {
  if (token.toUpperCase().startsWith('PAF-')) {
    downloadHandler.requestIdToken(
      (response) => authPerformed(response),
      (error) => noAuthPerformed(error),
    );
  } else {
    authPerformed(null);
  }
};

// noinspection ES6ModulesDependencies,JSUnresolvedVariable
/**
 * Builds default company data for anything not supplied by the API.
 * @returns {{companyName: string,
 *          pageHeaderLogo: (string | null),
 *          pageIntro: string,
 *          titleBackground: string}} An object containing default company data.
 */
export const createDefaultClientData = () => ({
  companyName: 'Erickson Immigration Group',
  pageHeaderLogo: null,
  pageIntro: 'Here you\'ll find your company\'s active LCAs.',
  titleBackground: `${process.env.PUBLIC_URL}/img/portal-header-min.jpg`,
});

/**
 * Builds default page context data for anything not supplied by the API.
 * @returns {{pageContext: string,
  *          skipToken: (string | null),
  *          limitRows: int}} An object containing default page context data.
  */
 export const createDefaultContextData = () => ({
   pageContext: 'lca',
   nextSkipToken: null,
   limitRows: 20
 });
