import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import DocumentsModal from './DocumentsModal';
import { postingMetadataDataType } from './PropDefinitions';

/**
 * Creates a table cell meant for displaying download links with a graphic displayed.
 * @param lcaNumber {string} LCA number for this paf entry.
 * @param jobTitle {string} Job title for this paf entry.
 * @param fileId {string} ID for this PAF entry.
 * @param fileMetadata {null | Object} List of known files for this PAF entry.
 * @param requestPafFileList {Function} The function to call when requesting PAF file list data.
 * @return {undefined} This function does not return a value.
 */
const OpenFileListTableCell = ({
  lcaNumber, jobTitle, fileId, fileMetadata, requestPostingFileList,
}) => {
  const [open, setOpenStatus] = useState(false);

  const openModal = () => {
    if (!open && fileMetadata && (!fileMetadata.files || fileMetadata.files.length === 0)) {
      requestPostingFileList(fileId);
    }

    setOpenStatus(!open);
  };

  return (
    <div>
      <IconButton onClick={() => openModal()}>
        <i className="fa fa-paperclip" />
      </IconButton>
      <DocumentsModal
        title={lcaNumber}
        jobTitle={jobTitle}
        fileMetadata={fileMetadata}
        open={open}
        toggleExpand={openModal}
      />
    </div>
  );
};
OpenFileListTableCell.propTypes = {
  lcaNumber: PropTypes.string.isRequired,
  jobTitle: PropTypes.string,
  fileId: PropTypes.string.isRequired,
  fileMetadata: postingMetadataDataType,
  requestPostingFileList: PropTypes.func.isRequired,
};
OpenFileListTableCell.defaultProps = {
  jobTitle: '',
  fileMetadata: {},
};

export default OpenFileListTableCell;
