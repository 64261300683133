import React from 'react';
import * as PropTypes from 'prop-types';
import LoginPortalBuildingIndicator from './LoginPortalBuildingIndicator';
import WrappedLoginForm from '../containers/WrappedLoginForm';

/**
 * Builds the login card body.
 * @param loginBranding {Object} An object containing branding information for the login page.
 * @return {jsx} A login card body component.
 */
const LoginCardBody = ({ loginBranding }) => {
  if (loginBranding) {
    return (
      <>
        <div className="loginLogo">
          <img
            src={loginBranding.loginLogo}
            alt="Company Logo"
          />
        </div>
        <WrappedLoginForm />
      </>
    );
  }

  return (<LoginPortalBuildingIndicator />);
};
LoginCardBody.propTypes = {
  loginBranding: PropTypes.shape({
    loginLogo: PropTypes.string,
  }),
};
LoginCardBody.defaultProps = {
  loginBranding: null,
};

export default LoginCardBody;
