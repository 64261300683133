import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import downloadHandler from '../api/downloadHandler';
import LoginPortal from './LoginPortal';
import WrappedDisplayPage from '../containers/WrappedDisplayPage';

// noinspection JSUnresolvedVariable,ES6ModulesDependencies
/**
 * Builds default login portal branding for anything not supplied by the API.
 * @returns {{loginLogo: string}} An object containing default login branding.
 */
const createDefaultLoginBranding = () => ({
  loginLogo: `${process.env.PUBLIC_URL}/img/eig-logo.png`,
});

class App extends React.Component {
  /**
   * Constructor for the app.
   * @param props {Object} Properties provided to this class.
   * @return {undefined} This function does not return a value.
   */
  constructor(props) {
    super(props);
    this.state = {
      loginBranding: null,
    };
  }

  /**
   * Fires when component mounts.
   * @return {undefined} This function does not return a value.
   */
  componentDidMount() {
    this.requestLoginBranding();
  }

  /**
   * Populates client data from a response.
   * @param response {object} The response to a data request.
   * @return {undefined} This function does not return a value.
   */
  populateBrandingData = (response) => {
    const mergedData = createDefaultLoginBranding();

    for (const property in response) {
      // eslint-disable-next-line no-prototype-builtins
      if (response.hasOwnProperty(property)) {
        if (response[property] != null) {
          mergedData[property] = response[property];
        }
      }
    }

    this.setState({ loginBranding: mergedData });
  };

  /**
   * Calls the web api requesting branding data.
   * @returns {undefined} This method does not return a value.
   */
  requestLoginBranding = () => {
    this.setState({ loginBranding: null });

    downloadHandler.requestBrandingData(
      window.location.href,
      (response) => this.populateBrandingData(response),
      null,
    );
  };

  /**
   * Renders the contents of the page.
   * @returns {undefined} This method does not return a value.
   */
  render() {
    const { loggedIn } = this.props;
    const { loginBranding } = this.state;

    if (!loggedIn) {
      return (
        <LoginPortal
          loginBranding={loginBranding}
        />
      );
    }

    return (
      <WrappedDisplayPage />
    );
  }
}
App.propTypes = {
  loggedIn: PropTypes.bool,
};
App.defaultProps = {
  loggedIn: true,
};

export default connect()(App);
