import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import * as PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { errorPromptDataType } from './PropDefinitions';

const styles = (theme) => ({
  errorSnackbar: {
    backgroundColor: theme.palette.error.dark,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

/**
 * Displays an error prompt in the bottom right corner of the screen.
 * @param {Object} classes An object defining css classes.
 * @param {{
 *   errorPromptOpen: boolean,
 *   errorMessage: string,
 *   errorTime: number,
 * }}
 * @param {function} closeHandler The function to call when the prompt should close.
 * @return {jsx} A prompt that notifies users of errors.
 */
function ErrorPrompt({
  classes, errorInfo, closeHandler,
}) {
  const { errorPromptOpen, errorMessage, errorTime } = errorInfo || {};

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={errorPromptOpen}
      autoHideDuration={6000}
      onClose={closeHandler}
      key={errorTime}
    >
      <SnackbarContent
        className={classes.errorSnackbar}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={(
          <span id="message-id" className={classes.errorSnackbar}>
            <i className="fa fa-exclamation-triangle" />
            &nbsp;
            {errorMessage}
          </span>
        )}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={closeHandler}
          >
            <i className="fa fa-times-circle" />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
}
ErrorPrompt.propTypes = {
  classes: PropTypes.shape({
    errorSnackbar: PropTypes.string,
    message: PropTypes.string,
  }).isRequired,
  errorInfo: errorPromptDataType.isRequired,
  closeHandler: PropTypes.func.isRequired,
};

export default withStyles(styles)(ErrorPrompt);
