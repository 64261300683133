import RequestBuilder from './requestBuilder';

/**
 * The constant string that defines a posting date that will require special formatting.
 * @type {string}
 */
const postingDateVariable = 'postingDate';

/**
 * The constant string that defines an expiration date that will require special formatting.
 * @type {string}
 */
const expiredDateVariable = 'expiredDate';

/**
 * The constant string that defines an paf expiration date that will require special formatting.
 * @type {string}
 */
const pafExpiryDateVariable = 'pafExpiryDate';
/**
 * Formats LCA entries.
 * @param {{
 *   LastDigitsLCA: (string),
 *   JobTitle: (string),
 *   FileNumber: (string),
 *   PostedDate: (string),
 *   Location: (string),
 *   OperatingCompany: (string),
 *   ID: (string)
 * }[]} jobListings A collection of LCA data to format.
 * @returns {{
 *    lcaNumber: (string),
 *    jobTitle: (string),
 *    fileNumber: (string),
 *    postingDate: (string),
 *    location: (string),
 *    operatingCompany: (string),
 *    fileId: (string)
 * }[]} A collection of formatted LCA data.
 */
function formatLcaListings(jobListings) {
  if (!jobListings) {
    return [];
  }

  // Left side is local name, right side is expected API name.
  const lcaJobListingMap = [
    ['lcaNumber', 'LastDigitsLCA'],
    ['jobTitle', 'JobTitle'],
    ['fileNumber', 'FileNumber'],
    [postingDateVariable, 'PostedDate'],
    ['location', 'Location'],
    ['operatingCompany', 'OperatingCompany'],
    ['fileId', 'ID'],
  ];

  // noinspection JSValidateTypes (Map does not specify return type)
  return jobListings.map((listing) => {
    const formattedListing = convertObjectFormat(lcaJobListingMap, listing);

    formattedListing.downloadUrl = RequestBuilder.buildLcaFileRequestUrl(formattedListing.fileId);

    return formattedListing;
  });
}

/**
 * Formats PAF entries.
 * @param {{
 *   LastDigitsLCA: (string),
 *   JobTitle: (string),
 *   FileNumber: (string),
 *   PostedDate: (string),
 *   Location: (string),
 *   OperatingCompany: (string),
 *   ID: (string),
 *   ExpiredDate: (string)
 * }[]} pafListings A collection of PAF data to format.
 * @returns {{
 *    lcaNumber: (string),
 *    jobTitle: (string),
 *    fileNumber: (string),
 *    postingDate: (string),
 *    location: (string),
 *    operatingCompany: (string),
 *    fileId: (string),
 *    expiredDate: (string)
 * }[]} A collection of formatted PAF data.
 */
function formatPafListings(pafListings) {
  if (!pafListings) {
    return [];
  }

  // Left side is local name, right side is expected API name.
  const pafListingMap = [
    ['lcaNumber', 'LastDigitsLCA'],
    ['jobTitle', 'JobTitle'],
    ['fileNumber', 'FileNumber'],
    [postingDateVariable, 'PostedDate'],
    ['fileId', 'ID'],
    [expiredDateVariable, 'ExpiredDate'],
    [pafExpiryDateVariable, 'PafExpirationDate'],
    ['location', 'Location'],
    ['operatingCompany', 'OperatingCompany'],
  ];

  // noinspection JSValidateTypes (Map does not specify return type)
  return pafListings.map((listing) => {
    const formattedListing = convertObjectFormat(pafListingMap, listing);

    formattedListing.files = [];

    return formattedListing;
  });
}

/**
 * Formats NOF entries.
 * @param {{
  *   jobTitle: (string),
  *   postedDate: (string),
  *   fileId: (string),
  *   Location: (string),
  *   OperatingCompany: (string),
  *   expiredDate: (string)
  * }[]} nofListings A collection of NOF data to format.
  * @returns {{
  *   jobTitle: (string),
  *   postingDate: (string),
  *   location: (string),
  *   operatingCompany: (string),
  *   expiredDate: (string)
  * }[]} A collection of formatted NOF data.
  */
function formatNofListings(nofListings) {
  if (!nofListings) {
    return [];
  }

  // Left side is local name, right side is expected API name.
  const nofJobListingMap = [
    ['jobTitle', 'JobTitle'],
    [postingDateVariable, 'PostedDate'],
    ['fileId', 'ID'],
    ['location', 'Location'],
    ['operatingCompany', 'OperatingCompany'],
    [expiredDateVariable, 'ExpiredDate']
  ];

  // noinspection JSValidateTypes (Map does not specify return type)
  return nofListings.map((listing) => {
    const formattedListing = convertObjectFormat(nofJobListingMap, listing);

    formattedListing.downloadUrl = RequestBuilder.buildNofFileRequestUrl(listing.ID);

    return formattedListing;
  });
}

/**
 * Converts expected properties from a received object into the local format.
 * @param {string[][]} propMap A collection of associated property names.
 * @param {Object} apiObject The data object received from the API.
 */
function convertObjectFormat(propMap, apiObject) {
  const formattedObject = {};

  for (let index = 0; index < propMap.length; index += 1) {
    const apiPropLabel = propMap[index][1];
    if (apiObject[apiPropLabel]) {
      const localPropLabel = propMap[index][0];
      formattedObject[localPropLabel] = applySpecialFormatting(
        localPropLabel,
        apiObject[apiPropLabel],
      );
    }
  }

  return formattedObject;
}

/**
 * Formats any LCA listings found in the provided data.
 * @param {{
 *   companyName: (string | null),
 *   loginLogo: (string | null),
 *   pageHeaderLogo: (string | null),
 *   helpContact: (string | null),
 *   pageIntro: (string | null),
 *   titleBackground: (string | null),
 *   companyMemo: (string | null),
 *   pafMemo: (number | null),
 *   PAFText: (string | null),
 *   nofText: (string | null)
 * }} clientData Company data that hasn't been formatted.
 * @returns {{
 *   companyName: (string | null),
 *   loginLogo: (string | null),
 *   pageHeaderLogo: (string | null),
 *   helpContact: (string | null),
 *   pageIntro: (string | null),
 *   titleBackground: (string | null),
 *   companyMemo: (string | null),
 *   jobListings: (array),
 *   pafIntro: (string | null),
 *   pageIntroForNofListings: (string | null)
 * }} Formatted company data.
 */
function formatCompanyData(clientData) {
  // Left side is local name, right side is expected API name.
  // Excludes jobListings, pafListings, nofListings as they are handled in other functions.
  const companyDataMap = [
    ['companyName', 'companyName'],
    ['loginLogo', 'loginLogo'],
    ['pageHeaderLogo', 'pageHeaderLogo'],
    ['helpContact', 'helpContact'],
    ['pageIntro', 'pageIntro'],
    ['titleBackground', 'titleBackground'],
    ['companyMemo', 'companyMemo'],    
    ['pafIntro', 'PAFText'],
    ['pageIntroForNofListings', 'nofText'],
    ['nextSkipToken', 'skipToken']
  ];

  const formattedData = {
    jobListings: [],
  };

  for (let index = 0; index < companyDataMap.length; index += 1) {
    // skip formatting for jobListings , pafListings, nofListings
    // because a special function will handle that.
    if (clientData[companyDataMap[index][1]]) {
      formattedData[companyDataMap[index][0]] = clientData[companyDataMap[index][1]];
    }
  }

  // noinspection JSValidateTypes
  return formattedData;
}

/**
 * Builds a URL to request the PAF memo file.
 * @param {number} pafMemoFileId The ID for the PAF memo file.
 * @returns {string | null} a URL that will request the PAF memo file.
 */
function buildPafMemoUrl(pafMemoFileId) {
  if (!pafMemoFileId) {
    return null;
  }

  return RequestBuilder.buildMemoFileRequestUrl(pafMemoFileId);
}

/**
 * Applies any special formatting that might be needed for a particular value.
 * If no formatting is needed it will return the original value.
 * @param {string} name The variable name to check for formatting needs.
 * @param {string | number} value The value to format.
 * @returns {string | number} A formatted value.
 */
function applySpecialFormatting(name, value) {
  switch (name) {
    case postingDateVariable:
    case expiredDateVariable:
      return formatDateString(value);
    case pafExpiryDateVariable:
      return formatDateString(value);
    default:
      return value;
  }
}

/**
 * Formats a date string to the format for displaying on the UI.
 * A null or empty string is supplied then an empty string will be returned.
 * @param {string} date Date string to format.
 * @returns {string} A formatted date string.
 */
function formatDateString(date) {
  if (!date || !date.trim()) return '';

  const options = { year: 'numeric', month: 'numeric', day: 'numeric' };

  let convertedDate = new Date(date);

  // noinspection JSCheckFunctionSignatures
  if (convertedDate instanceof Date && isNaN(convertedDate)) {
    // intended to convert 'mm-dd-yyyy' to 'mm/dd/yyyy' if first conversion fails.
    const modifiedDateString = date.replace('-', '/');
    convertedDate = new Date(modifiedDateString);
  }

  return convertedDate.toLocaleDateString('en-US', options);
}

/**
 * Formats company data from the API definition to the local definition.
 * @param {{
 *   companyName: (string | null),
 *   loginLogo: (string | null),
 *   pageHeaderLogo: (string | null),
 *   helpContact: (string | null),
 *   titleBackground: (string | null),
 *   companyMemo: (string | null),
 *   pafMemo: (number | null),
 *   PAFText: (string | null),
 *   pageIntro: (string | null),
 *   nofText: (string | null),
 *   jobListings: (Object[] | null),
 *   pafListings: (Object[] | null),
 *   nofListings: (Object[] | null),
 * }} responseJson Company data object from API.
 * @return {{
 *   companyName: (string | null),
 *   loginLogo: (string | null),
 *   pageHeaderLogo: (string | null),
 *   helpContact: (string | null),
 *   titleBackground: (string | null),
 *   companyMemo: (string | null),
 *   pafIntro: (string | null),
 *   pageIntro: (string | null),
 *   pageIntroForNofListings: (string | null),
 * }} A formatted company data object.
 */
const convertResponse = (responseJson) => {
  const formattedCompanyData = formatCompanyData(responseJson);

  formattedCompanyData.jobListings = formatLcaListings(responseJson.jobListings);

  if (responseJson.pafListings || responseJson.pafMemo) {
    formattedCompanyData.pafListings = formatPafListings(responseJson.pafListings);
    formattedCompanyData.companyMemo = buildPafMemoUrl(responseJson.pafMemo);
  } else {
    formattedCompanyData.nofListings = formatNofListings(responseJson.nofListings);
  }

  return formattedCompanyData;
};

export default convertResponse;
