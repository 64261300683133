import { connect } from 'react-redux';
import { requestFile, lcaMetadataRequest } from '../actions';
import LcaGridBody from '../components/LcaGridBody';
import { lcaMetadataAdd } from '../actions/index';

/**
 * Identifier for downloads initiated by this control.
 * @type {string}
 */
const downloadCategory = 'lca';

/**
 * Gets the props to send to the wrapped component.
 * @param {{
 *  fileDownload: {
 *    category: string,
 *    fileId: string,
 *    filename: string,
 *    location: string
 *   }[]
 *  }} state The current state.
 * @param {{rows: Array}} ownProps The props provided to this component.
 * @returns {{
 *  rows: Array,
 *  filesDownloading: Array,
 * }} The props to send to the wrapped component.
 */
const mapStateToProps = (state, ownProps) => ({
  rows: ownProps.rows,
  filesDownloading: state.fileDownload.filter((download) => download.category === downloadCategory),
  progressing: state.paginationContext.progressing,
  lcaMetadata: state.lcaMetadata,
});

/**
 * Gets the functions to supply to the wrapped component.
 * @param {function} dispatch A function that wraps another function in Redux state flow.
 * @returns {{
 *  requestFile: function,
 * }} The functions to supply to the wrapped component.
 */
const mapDispatchToProps = (dispatch) => ({
  addLcaToLcaMetadataList: (lcaId) => dispatch(lcaMetadataAdd(lcaId)),
  downloadLcaFileMetadata: (lcaId) => dispatch(lcaMetadataRequest(lcaId)),
  requestFile: (fileUrl, fileId, defaultFilename, location) => {
    return dispatch(requestFile(fileUrl, downloadCategory, fileId, defaultFilename, location));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LcaGridBody);
