import { CircularProgress, withStyles } from '@material-ui/core';

/**
 * A pre-branded loading indicator.
 * @type {React.ComponentClass<CircularProgress>}
 */
export default withStyles({
  root: {
    color: '#58b228',
  },
})(CircularProgress);
