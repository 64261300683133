import { connect } from 'react-redux';
import { requestFile } from '../actions';
import NofGridBody from '../components/NofGridBody';

/**
 * Identifier for downloads initiated by this control.
 * @type {string}
 */
const downloadCategory = 'nof';

/**
 * Gets the props to send to the wrapped component.
 * @param {{
 *  fileDownload: {
 *    category: string,
 *    fileId: string,
 *    filename: string,
 *   }
 *  }} state The current state.
 * @param {{rows: Array}} ownProps The props provided to this component.
 * @returns {{
 *  rows: Array,
 *  filesDownloading: Array,
 * }} The props to send to the wrapped component.
 */
const mapStateToProps = (state, ownProps) => ({
  rows: ownProps.rows,
  filesDownloading: state.fileDownload.filter((download) => download.category === downloadCategory),
  progressing: state.paginationContext.progressing
});

/**
 * Gets the functions to supply to the wrapped component.
 * @param {function} dispatch A function that wraps another function in Redux state flow.
 * @returns {{
 *  requestFile: function,
 * }} The functions to supply to the wrapped component.
 */
const mapDispatchToProps = (dispatch) => ({
  requestFile: (fileUrl, fileId, defaultFilename) => dispatch(requestFile(fileUrl, downloadCategory, fileId, defaultFilename)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NofGridBody);
