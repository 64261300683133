import {
  PAF_METADATA_DOWNLOAD_BEGIN,
  PAF_METADATA_DOWNLOAD_SUCCESS,
  PAF_METADATA_DOWNLOAD_ERROR,
  PAF_METADATA_ADD,
} from '../actions';

/**
 * Returns a new state depending on the provided action.
 * @param {{
 *   pafId: string,
 *   files: Object[],
 *   fileListDownloading: boolean,
 *   fileListDownloadErrorMessage: string,
 * }[]} state The current state.
 * @param {{
 *   type: string,
 *   pafId: string,
 *   fileList: Object[],
 *   errorMessage: string,
 * }} action An object containing parameters and the action to perform.
 * @returns {{
 *   pafId: string,
 *   files: Object[],
 * }[]} The new state to apply.
 */
const pafMetadata = (state = [], action) => {
  switch (action.type) {
    // Add a PAF to the list of known PAFs.
    case PAF_METADATA_ADD:
      if (state.find((pafMeta) => pafMeta.pafId === action.pafId)) {
        return state;
      }

      return [
        ...state,
        {
          pafId: action.pafId,
          files: null,
        },
      ];
    // Mark a PAF as having its metadata downloaded.
    case PAF_METADATA_DOWNLOAD_BEGIN:
      return state.map((paf) => {
        if (paf.pafId === action.pafId) {
          return {
            ...paf,
            fileListDownloading: true,
            fileListDownloadErrorMessage: null,
          };
        }
        return paf;
      });
    // Update a PAF entry with its metadata.
    case PAF_METADATA_DOWNLOAD_SUCCESS:
      return state.map((paf) => {
        if (paf.pafId === action.pafId) {
          return {
            ...paf,
            fileListDownloading: false,
            files: action.fileList,
          };
        }
        return paf;
      });
    // Updates a PAF as having failed to download its metadata.
    case PAF_METADATA_DOWNLOAD_ERROR:
      return state.map((paf) => {
        if (paf.pafId === action.pafId) {
          return {
            ...paf,
            fileListDownloading: false,
            files: [],
            fileListDownloadErrorMessage: action.errorMessage,
          };
        }
        return paf;
      });
    // Makes no changes to the state.
    default:
      return state;
  }
};

export default pafMetadata;
