import {
  LOGIN_BEGIN, LOGIN_ERROR, LOGIN_RESET, LOGIN_SUCCESS, LOGIN_SUCCESS_DATA_ERROR
} from '../actions';

/**
 * Returns a new state depending on the provided action.
 * @param {{
 *  currentToken: string | null,
 *  clientJson: Object | null,
 *  loggedIn: boolean,
 *  errorMessage: string | null,
 *  processingLogin: boolean
 * }} state The current state.
 * @param {{
 *   type: string,
 *   clientJson: Object,
 *   token: string,
 *   errorMessage: string,
 * }} action An object containing parameters and the action to perform.
 * @returns {{
 *  currentToken: string | null,
 *  clientJson: Object | null,
 *  loggedIn: boolean,
 *  errorMessage: string | null,
 *  processingLogin: boolean
 * }} The new state to apply.
 */
const clientData = (state = defaultState(), action) => {
  switch (action.type) {
    // Updates state to reflect a login in progress.
    case LOGIN_BEGIN:
      return {
        ...state,
        processingLogin: true,
        clientJson: null,
        currentToken: null,
        errorMessage: null,
        loggedIn: false,
      };
    // Updates state with data from successful login.
    case LOGIN_SUCCESS:
      return {
        ...state,
        processingLogin: false,
        clientJson: action.clientJson,
        currentToken: action.token,
        loggedIn: true,
      };
    // Updates state with data from successful login, but failure to fetch api data
    case LOGIN_SUCCESS_DATA_ERROR:
      return {
        ...state,        
        processingLogin: false,
        errorMessage: action.errorMessage,
        currentToken: action.token,
        loggedIn: true,
      };
    // Updates state with the error information from the failed login.
    case LOGIN_ERROR:
      return {
        ...state,
        processingLogin: false,
        errorMessage: action.errorMessage,
      };
    // Restores the default state.
    case LOGIN_RESET:
      return defaultState();
    // Makes no changes to the state.
    default:
      return state;
  }
};

/**
 * The default state to use.
 * @returns {{
 *  currentToken: null,
 *  clientJson: null,
 *  loggedIn: boolean,
 *  errorMessage: null,
 *  processingLogin: boolean
 * }} A new instance of the default state.
 */
const defaultState = () => ({
  loggedIn: false,
  clientJson: null,
  currentToken: null,
  processingLogin: false,
  errorMessage: null,
});

export default clientData;
