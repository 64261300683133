import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { TableBody, TableRow, withStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import {
  Grid, Table, TableHeaderRow, PagingPanel, TableFilterRow, TableGroupRow,
  GroupingPanel, DragDropProvider, Toolbar, SearchPanel,
} from '@devexpress/dx-react-grid-material-ui';
import {
  PagingState, IntegratedPaging, FilteringState, IntegratedFiltering,
  GroupingState, IntegratedGrouping, SearchState,
} from '@devexpress/dx-react-grid';
import PostingTableCell from './PostingTableCell';
import OpenFileListTableCell from './OpenFileListTableCell';
import { lcaListingsDataType, postingMetadataDataType } from './PropDefinitions';
import PageLoadingIndicator from './PageLoadingIndicator';

/**
 * Gets the styles to apply to this component.
 * @returns {{
 *  tableStriped: {
 *    color: string,
 *    '& tbody tr:nth-of-type(odd)': {
 *      backgroundColor: string
 *    }
 *  },
 *  customCell: {
 *    whiteSpace: string,
 *    width: string,
 *    marginTop: theme.spacing(3),
 *    overflowX: string,
 *    fontSize: int,
 *    color: string,
 *    fontFamily: string
 *  },
 *  customHeaderCell: {
 *    whiteSpace: string,
 *    height: string,
 *    backgroundColor: string,
 *    overflowX: string,
 *    fontSize: int,
 *    color: string,
 *    fontFamily: string
 *  }
 * }}
 */
const styles = (theme) => ({
  customCell: {
    whiteSpace: 'normal',
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    fontSize: 12,
    color: '#6B6B6B',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  customHeaderCell: {
    whiteSpace: 'normal',
    height: 40,
    backgroundColor: '#6B6B6B',
    color: theme.palette.common.white,
    fontSize: 16,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  tableStriped: {
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: '#f5f5f5',
    },
    color: '#9F9F9F',
  },
});

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
);

const CustomHeaderCellBase = ({ classes, ...restProps }) => (
  <TableHeaderRow.Cell className={classes.customHeaderCell} {...restProps} />
);

const TableComponentBase = ({ classes, ...restProps }) => (
  <Table.Table
    {...restProps}
    className={classes.tableStriped}
  />
);

export const CustomizeTableCell = withStyles(styles)(CustomTableCellBase);
export const CustomizeHeaderCell = withStyles(styles)(CustomHeaderCellBase);
export const TableComponent = withStyles(styles, { name: 'TableComponent' })(TableComponentBase);

class LcaGridBody extends Component {
  componentDidMount() {
    const { rows, addLcaToLcaMetadataList } = this.props;

    if (!rows) return;

    rows.forEach((dataRow) => {
      addLcaToLcaMetadataList(dataRow.fileId);
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      filteringStateColumnExtensions: [
        { columnName: '', filteringEnabled: false }
      ],
      groupingStateColumnExtensions: [
        { columnName: '', groupingEnabled: false },
      ]
    }
  }

  /**
   * Renders the component.
   * @returns {jsx} The LCA grid body.
   */
  render() {
    const { rows, lcaMetadata, progressing, downloadLcaFileMetadata } = this.props;

    const defaultColumnWidths = [
      { columnName: 'lcaNumber', width: 'auto' },
      { columnName: 'jobTitle', width: 'auto' },
      { columnName: 'fileNumber', width: 'auto' },
      { columnName: 'postingDate', width: '75px' },
      { columnName: 'location', width: 'auto' },
      { columnName: 'operatingCompany', width: 'auto' },
      { columnName: '', width: '50px' },
    ];

    const { filteringStateColumnExtensions } = this.state;

    const { groupingStateColumnExtensions } = this.state;

    const columns = [
      { name: 'lcaNumber', title: 'LCA Number' },
      { name: 'jobTitle', title: 'Job Title' },
      { name: 'fileNumber', title: 'File Number' },
      { name: 'postingDate', title: 'Posting Date' },
      { name: 'location', title: 'Location' },
      { name: 'operatingCompany', title: 'Operating Company' },
      {
        name: '',
        getCellValue: (row) => (
          <OpenFileListTableCell
            lcaNumber={row.lcaNumber}
            jobTitle={row.jobTitle}
            fileId={row.fileId}
            fileMetadata={lcaMetadata.find((lcaMeta) => lcaMeta.lcaId === row.fileId)}
            requestPostingFileList={downloadLcaFileMetadata}
          />
        ),
      },
    ];

    if (progressing) {
      return (
        <TableBody className="pageLoadingTable">
          <TableRow>
            <PostingTableCell colSpan={5}>
              <PageLoadingIndicator />
            </PostingTableCell>
          </TableRow>
        </TableBody>
      );
    }

    return (
      <Paper>
        <Grid rows={rows} columns={columns}>
          <DragDropProvider />
          <SearchState defaultValue="" />
          <GroupingState defaultGrouping={[]} columnExtensions={groupingStateColumnExtensions} />
          <FilteringState defaultFilters={[]} columnExtensions={filteringStateColumnExtensions} />
          <IntegratedFiltering />

          <PagingState defaultCurrentPage={0} defaultPageSize={20} />
          <IntegratedGrouping />
          <IntegratedPaging />

          <Table cellComponent={CustomizeTableCell} tableComponent={TableComponent} columnExtensions={defaultColumnWidths} />
          <TableHeaderRow cellComponent={CustomizeHeaderCell} />
          <TableGroupRow />
          <Toolbar />
          <SearchPanel />
          <TableFilterRow />
          <GroupingPanel showGroupingControls />
          <PagingPanel />
        </Grid>
      </Paper>
    );
  }
}

LcaGridBody.propTypes = {
  rows: lcaListingsDataType.isRequired,
  filesDownloading: PropTypes.arrayOf(PropTypes.object).isRequired,
  lcaMetadata: PropTypes.arrayOf(postingMetadataDataType),
  progressing: PropTypes.bool,
  requestFile: PropTypes.func.isRequired,
  addLcaToLcaMetadataList: PropTypes.func.isRequired,
  downloadLcaFileMetadata: PropTypes.func.isRequired,
};

LcaGridBody.defaultProps = {
  lcaMetadata: [],
};

export default withStyles(styles)(LcaGridBody);
