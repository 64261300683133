/**
 * Executes a request and processes the result.
 * @param request {Object} The request to execute.
 * @param defaultFileName {string} The default filename to use if none is supplied.
 * @param resolve {function(*=): *} Function to execute on success.
 * @param reject {function(*=): *} Function to execute on failure/error.
 * @return {undefined} This function does not return a value.
 */
import requestBuilder from '../helpers/requestBuilder';

function performFileRequest(request, defaultFileName, reject, resolve) {
  fetch(request.url, request)
    .then((response) => {
      if (response.ok) {
        return processResponse(response, defaultFileName);
      }

      if (response.status === 404) {
        throw new Error('Could not find file.');
      } else if (response.status === 401) {
        throw new Error('You are not authorized to view this file');
      } else {
        throw new Error('An unknown error occurred.');
      }
    })
    .catch((error) => reject(error))
    .finally(resolve);
}

/**
 * Handles a request for a file.
 * @param fileUrl {string} The URL to make the request to.
 * @param defaultFileName {string} The default filename to use if none is supplied.
 * @param resolve {function(*=): *} Function to execute on success.
 * @param reject {function(*=): *} Function to execute on failure/error.
 * @return {undefined} This function does not return a value.
 */
export const requestFile = (fileUrl, defaultFileName, resolve, reject) => {
  const request = {};

  try {
    Object.assign(request, requestBuilder.getRequestOptions());
    request.url = new URL(fileUrl);
  } catch (error) {
    reject(error);
    return;
  }

  performFileRequest(request, defaultFileName, reject, resolve);
};

/**
 * Handles an authenticated request for a file.
 * @param fileUrl {string} The URL to make the request to.
 * @param idToken {string} The authentication token to submit with the request.
 * @param defaultFileName {string} The default filename to use if none is supplied.
 * @param resolve {function(*=): *} Function to execute on success.
 * @param reject {function(*=): *} Function to execute on failure/error.
 * @return {undefined} This function does not return a value.
 */
export const requestAuthenticatedFile = (fileUrl, idToken, defaultFileName, resolve, reject) => {
  const request = {};

  try {
    Object.assign(request, requestBuilder.getRequestOptions());

    request.headers.Authorization = `Bearer ${idToken}`;
    request.url = new URL(fileUrl);
  } catch (error) {
    reject(error);
    return;
  }

  performFileRequest(request, defaultFileName, reject, resolve);
};

/**
 * Process the response from the file request.
 * @param response {Response} The response from the file request.
 * @param defaultFileName {string} The default filename to use if none is supplied.
 */
const processResponse = (response, defaultFileName) => {
  const fileName = getFilename(response, defaultFileName);
  response.blob().then((blob) => saveBlob(response, blob, fileName));
};

/**
 * Prompts the user to download the file supplied from a response.
 * @param response {Response} The response received from the file request.
 * @param blob {Blob} The blob received from the response.
 * @param fileName {string} The name to insert when the download prompt appears.
 */
const saveBlob = (response, blob, fileName) => {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  const newBlob = new Blob([blob], { type: response.headers.get('content-type') });

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  }

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob);
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = data;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  setTimeout(() => window.URL.revokeObjectURL(data), 1000);
};

/**
 * Gets the filename provided with a response or returns the default provided name.
 * @param response {Response} The response from a file request.
 * @param defaultFileName {string} The filename to use if no filename is defined.
 * @returns {string} The filename to use with this file.
 */
const getFilename = (response, defaultFileName) => {
  const disposition = response.headers.get('Content-Disposition');
  if (disposition && disposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      return matches[1].replace(/['"]/g, '');
    }
  }
  return `${defaultFileName}`;
};
