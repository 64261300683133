import requestClientData from './requests/clientDataHandler';
import requestAuthenticatedClientData from './requests/authenticatedClientDataHandler';
import requestPafFileList from './requests/pafFileListHandler';
import requestIdToken from './requests/authHandler';
import { requestFile, requestAuthenticatedFile } from './requests/fileRequestHandler';
import requestLcaFileList from './requests/lcaFileListHandler';

export default class downloadHandler {
  /**
   * Handles a request for LCA data.
   * @param accessCode {string} The access code to submit with the request.
   * @param resolve {function(*=): *} Function to execute on success.
   * @param reject {function(*=): *} Function to execute on failure/error.
   * @return {undefined} This function does not return a value.
   */
  static requestClientData = (accessCode, paginationContext, resolve, reject) => {
    requestClientData(accessCode, paginationContext, resolve, reject);
  };

  /**
   * Handles a request for LCA data.
   * @param accessCode {string} The access code to submit with the request.
   * @param idToken {string} The authentication token to submit with the request.
   * @param resolve {function(*=): *} Function to execute on success.
   * @param reject {function(*=): *} Function to execute on failure/error.
   * @return {undefined} This function does not return a value.
   */
  static requestAuthenticatedClientData = (accessCode, idToken, paginationContext, resolve, reject) => {
    requestAuthenticatedClientData(accessCode, idToken, paginationContext, resolve, reject);
  };

  /**
   * Handles a request for the file list of a LCA.
   * @param accessCode {string} The access code to submit with the request.
   * @param lcaId {string} The ID fo the LCA to request information for.
   * @param resolve {function(*=): *} Function to execute on success.
   * @param reject {function(*=): *} Function to execute on failure/error.
   * @return {undefined} This function does not return a value.
   */
  static requestLcaFileList = (accessCode, lcaId, resolve, reject) => {
    requestLcaFileList(accessCode, lcaId, resolve, reject);
  };

   /**
   * Handles a request for the file list of a PAF.
   * @param accessCode {string} The access code to submit with the request.
   * @param idToken {string} The authentication token to submit with the request.
   * @param pafId {string} The ID fo the PAF to request information for.
   * @param resolve {function(*=): *} Function to execute on success.
   * @param reject {function(*=): *} Function to execute on failure/error.
   * @return {undefined} This function does not return a value.
   */
   static requestPafFileList = (accessCode, idToken, pafId, resolve, reject) => {
    requestPafFileList(accessCode, idToken, pafId, resolve, reject);
  };

  // noinspection JSUnusedLocalSymbols
  /**
   * Handles a file request and attempts to save the result to the computer.
   * @param pageUrl {string} The page url to use for client lookup.
   * @param resolve {function(object): *} Function to execute on success.
   * @param reject {function(string): *} Function to execute on failure/error.
   * @return {undefined} This function does not return a value.
   */
  static requestBrandingData = (pageUrl, resolve, reject) => {
    // PLACEHOLDER
    resolve({});
  };

  /**
   * Gets a authentication token.
   * @param resolve {function(string): *} Function to execute on success.
   * @param reject {function(string): *} Function to execute on failure/error.
   */
  static requestIdToken = (resolve, reject) => {
    requestIdToken(resolve, reject);
  };

  /**
   * Downloads a file and attempts to save it to the local machine.
   * @param fileUrl {string} The URL to make the request to.
   * @param defaultFileName {string} The filename to use if none is provided with the file.
   * @param resolve {function} Function to execute on success.
   * @param reject {function} Function to execute on failure/error.
   */
  static requestFile = (fileUrl, defaultFileName, resolve, reject) => {
    requestFile(fileUrl, defaultFileName, resolve, reject);
  };

  /**
   * Gets a file that requires authentication.
   * @param fileUrl {string} The URL to make the request to.
   * @param idToken {string} The authentication token to send with the request.
   * @param defaultFileName {string} The filename to use if none is provided with the file.
   * @param resolve {function} Function to execute on success.
   * @param reject {function} Function to execute on failure/error.
   */
  static requestAuthenticatedFile = (fileUrl, idToken, defaultFileName, resolve, reject) => {
    requestAuthenticatedFile(fileUrl, idToken, defaultFileName, resolve, reject);
  }
}
