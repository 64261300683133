import React from 'react';
import * as PropTypes from 'prop-types';
import { Button, TextField } from '@material-ui/core';
import LoginProgressIndicator from './LoginProgressIndicator';

/**
 * Builds the login form.
 * @param processingLogin {bool} whether or not the page is loading data.
 * the access code entered.
 * @param errorMessage {string} The most recent error message to display.
 * @param onClick {function} Handles login button press.
 * @return {jsx} A login form component.
 */
const LoginForm = ({ processingLogin, errorMessage, onClick }) => {
  let input;

  if (processingLogin) {
    return (<LoginProgressIndicator />);
  }

  const cachedToken = sessionStorage.getItem('cachedAccessToken');

  if (cachedToken) {
    onClick(cachedToken);
  }

  return (
    <div className="loginFormDiv">
      <div className="loginMessage">
        Please provide the token provided to you to view postings for your company.
      </div>
      <div className="errorMessage">{errorMessage}</div>
      <form
        className="loginForm"
        onSubmit={(e) => {
          e.preventDefault();
          const token = input.value.trim();
          if (!token) {
            return;
          }
          onClick(token);
        }}
      >
        <TextField
          autoFocus
          inputRef={(node) => { input = node; }}
          className="accessCodeInput"
          label="Enter your access code"
        />
        <Button className="loginButton" type="submit">
          LOG IN
        </Button>
      </form>
    </div>
  );
};
LoginForm.propTypes = {
  processingLogin: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};
LoginForm.defaultProps = {
  errorMessage: null,
};

export default LoginForm;
