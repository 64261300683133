import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { TableBody, TableRow, withStyles } from '@material-ui/core';
import PostingTableCell from './PostingTableCell';
import PostingDownloadLinkTableCell from './PostingDownloadLinkTableCell';
import { nofListingsDataType } from './PropDefinitions';
import PageLoadingIndicator from './PageLoadingIndicator';
import Paper from '@material-ui/core/Paper';
import { Grid, Table, TableHeaderRow, PagingPanel, TableFilterRow, TableGroupRow,
  GroupingPanel, DragDropProvider, Toolbar, SearchPanel } from '@devexpress/dx-react-grid-material-ui';
import { PagingState, IntegratedPaging, FilteringState, IntegratedFiltering,
 GroupingState, IntegratedGrouping, SearchState } from '@devexpress/dx-react-grid';

/**
 * Gets the styles that should be applied to this component.
 * @param {Object} theme Contains theme styling info.
 * @returns {{
 *  tableStriped: {
 *    color: string,
 *    '& tbody tr:nth-of-type(odd)': {
 *      backgroundColor: string
 *    }
 *  },
 *  customCell: {
 *    whiteSpace: string,
 *    width: string,
 *    marginTop: theme.spacing(3),
 *    overflowX: string,
 *    fontSize: int,
 *    color: string,
 *    fontFamily: string
 *  },
 *  customHeaderCell: {
 *    whiteSpace: string,
 *    height: string,
 *    backgroundColor: string,
 *    overflowX: string,
 *    fontSize: int,
 *    color: string,
 *    fontFamily: string
 *  }
 * }}
 */
const styles = (theme) => ({
  customCell: {
    whiteSpace: "normal",
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    fontSize: 14,
    color: '#6B6B6B',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
  },
  customHeaderCell: {
    whiteSpace: "normal",
    height: 40,
    backgroundColor: '#6B6B6B',
    color: theme.palette.common.white,
    fontSize: 16,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  tableStriped: {
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: '#f5f5f5',
    },
    color: '#9F9F9F'
  },
  customizePaper: {
    backgroundColor: '#f5f5f5'
  }
});

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />  
);

const CustomHeaderCellBase = ({ classes, ...restProps }) => (  
  <TableHeaderRow.Cell className={classes.customHeaderCell} {...restProps} />
);

const TableComponentBase = ({ classes, ...restProps }) => (
  <Table.Table
    {...restProps}
    className={classes.tableStriped}
  />
);

export const CustomizeTableCell = withStyles(styles)(CustomTableCellBase);
export const CustomizeHeaderCell = withStyles(styles)(CustomHeaderCellBase);
export const TableComponent = withStyles(styles, { name: 'TableComponent' })(TableComponentBase);

/**
 * Builds the grid body of the NOF page.
 * @param {object} classes contains a collection of class styles to use.
 * @param {Array} rows The array to populate the grid with.
 * @param {{
 *  category: string,
 *  fileId: string,
 *  filename: string,
 * }[]} filesDownloading A list of files currently being downloaded.
 * @param {function} requestFile A function that will download a given file.
 * @return {JSX.Element} The grid body of the NOF page.
 */
const NofGridBody = ({
  rows, filesDownloading, progressing, requestFile,
}) => {
  
  const defaultColumnWidths = [
    { columnName: 'jobTitle', width: 'auto' },
    { columnName: 'postingDate', width: 'auto' },
    { columnName: 'expirationDate', width: 'auto' },
    { columnName: '', width: '50px' }
  ];

  const columns = [    
    { name: 'jobTitle', title: 'Job Title' },
    { name: 'postingDate', title: 'Posting Date' },
    { name: 'expirationDate', title: 'Expiration Date', getCellValue: row => (row.expiredDate) },    
    { name: '', title: '', 
        getCellValue: row => <PostingDownloadLinkTableCell
          requestFile={() => requestFile(row.downloadUrl, row.fileId, row.jobTitle)}
          downloadInProgress={filesDownloading.some((download) => download.fileId === row.fileId)}
               /> },
  ];

  const filteringStateColumnExtensions = [{ columnName: '', filteringEnabled: false }];

  const [groupingStateColumnExtensions] = useState([
    { columnName: '', groupingEnabled: false }    
  ]);

  if (progressing) {
    return (
      <TableBody class="pageLoadingTable">
        <TableRow>
          <PostingTableCell colSpan={6}>
            <PageLoadingIndicator />
          </PostingTableCell>
        </TableRow>
      </TableBody>);
  }

  return (
     <Paper>   
      <Grid rows = {rows} columns = {columns} className="customizePaper">
        <DragDropProvider />
        <SearchState defaultValue="" />
        <GroupingState defaultGrouping={[]} columnExtensions={groupingStateColumnExtensions} />
        <FilteringState defaultFilters={[]} columnExtensions={filteringStateColumnExtensions} />
        <IntegratedFiltering />
        <PagingState defaultCurrentPage={0} defaultPageSize={20} />
        <IntegratedGrouping />
        <IntegratedPaging />
        <Table cellComponent={CustomizeTableCell} tableComponent={TableComponent} columnExtensions={defaultColumnWidths}/>
        <TableHeaderRow cellComponent={CustomizeHeaderCell} />
        <TableGroupRow />
        <Toolbar />
        <SearchPanel />
        <TableFilterRow />
        <GroupingPanel showGroupingControls />
        <PagingPanel />
      </Grid>
    </Paper>
  );
};
NofGridBody.propTypes = {
  classes: PropTypes.shape({
    row: PropTypes.string,
  }).isRequired,
  rows: nofListingsDataType.isRequired,
  filesDownloading: PropTypes.arrayOf(PropTypes.string).isRequired,
  progressing: PropTypes.bool,
  requestFile: PropTypes.func.isRequired,
};

export default withStyles(styles)(NofGridBody);
