import RequestBuilder from '../api/helpers/requestBuilder';
import downloadHandler from '../api/downloadHandler';
import {
  createDefaultClientData,
  handleAuthRequirement,
  sanitizeInput,
  createDefaultContextData,
} from './clientDataLogic';
import {loginEndpoint} from '../api/requests/authHandler';

// Login actions
export const LOGIN_BEGIN = 'LOGIN_BEGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_RESET = 'LOGIN_RESET';
export const LOGIN_SUCCESS_DATA_ERROR = 'LOGIN_SUCCESS_DATA_ERROR';

/**
 * Defines an action that updates state to reflect a login being performed.
 * @returns {{type: *}}
 */
export const loginBegin = () => ({
  type: LOGIN_BEGIN,
});

/**
 * Defines an action that updates state with login success information.
 * @param {Object} clientJson The data returned by the login.
 * @param {string} token The token used for the login.
 * @returns {{clientJson: *, type: *, token: *}}
 */
export const loginSuccess = (clientJson, token) => ({
  type: LOGIN_SUCCESS,
  clientJson,
  token,
});

/**
 * Defines an action that updates state with login success information, 
 * but adding an error indicating API failure 
 * @param {string} token The token used for the login.
 * @param {string} errorMessage The error returned by the API.
 * @returns {{errorMessage: *, type: *, token: *}}
 */
export const loginSuccessDataError = (token, errorMessage) => ({
  type: LOGIN_SUCCESS_DATA_ERROR,
  token,
  errorMessage
});

/**
 * Defines an action that updates state with login failure information.
 * @param {string} errorMessage The error message for this issue.
 * @returns {{errorMessage: *, type: *}}
 */
export const loginError = (errorMessage) => ({
  type: LOGIN_ERROR,
  errorMessage,
});

/**
 * Defines an action that resets the state.
 * @returns {{type: *}}
 */
export const loginReset = () => ({
  type: LOGIN_RESET,
});

/**
 * Gets a function that will perform a login with the given token.
 * @param {string} token The token to use when logging in.
 * @returns {Function} A function that can be used to perform a login.
 */
export const performLogin = (token, paginationContext) => (dispatch) => {
  dispatch(loginBegin());

  const cleanToken = sanitizeInput(token);
  if (!paginationContext) {
    paginationContext = createDefaultContextData();
  }
  RequestBuilder.accessToken = cleanToken;
  sessionStorage.setItem('cachedAccessToken', token);

  handleAuthRequirement(
    token,
    (idToken) => {
        downloadHandler.requestClientData(
          cleanToken,
          paginationContext,
          (response) => dispatch(processLoginData(cleanToken, response)),
          (errorMessage) => {
            sessionStorage.clear();
            return dispatch(loginError(errorMessage));
          },
        );
    },
    () => {},
  );
};

/**
 * Gets a function that will load postings data with the given token.
 * @param {string} token The token to use when logging in.
 * @returns {paginationContext} A context to get the data.
 */
export const loadPostings = (token, paginationContext) => (dispatch) => {
  dispatch(pageProcessingBegin());

  const cleanToken = sanitizeInput(token);
  if (!paginationContext) {
    paginationContext = createDefaultContextData();
  }
  RequestBuilder.accessToken = cleanToken;

  handleAuthRequirement(
    token,
    (idToken) => {
      if (idToken && paginationContext.pageContext === 'paf') {
        downloadHandler.requestAuthenticatedClientData(
          cleanToken,
          idToken,
          paginationContext,
          (response) => dispatch(processLoginData(cleanToken, response)),
          (errorMessage) => {
            if (errorMessage === 'Access not authorized.') {
              sessionStorage.clear();
              window.location.href = loginEndpoint;
            }
            else {
              return dispatch(processDataError(cleanToken, paginationContext, errorMessage));
            }            
          },
        );
      } else {
        downloadHandler.requestClientData(
          cleanToken,
          paginationContext,
          (response) => dispatch(processLoginData(cleanToken, response)),
          (errorMessage) => {
            return dispatch(processDataError(cleanToken, paginationContext, errorMessage));
          },
        );
      }
    },
    () => {},
  );
};

/**
 * Gets a function that forwards api error to relevant components.
 * @param token {string} The token used to obtain the current data
 * @param paginationContext {paginationContext} context to process data.
 * @param errorMessage {Response} The error message returned by API.
 * @return {Function} A function that populates client data from a response.
 */
const processDataError = (token, paginationContext, errorMessage) => (dispatch) => {
  dispatch(errorPromptTrigger(errorMessage));
  dispatch(loginSuccessDataError(token, errorMessage));
  dispatch(paginationSuccess(null, paginationContext.paginated));
};

/**
 * Gets a function that populates client data from a response.
 * @param token {string} The token used to obtain the current data
 * @param response {Response} The response to a data request.
 * @return {Function} A function that populates client data from a response.
 */
const processLoginData = (token, response) => (dispatch) => {
  const mergedData = createDefaultClientData();

  for (const property in response) {
    // eslint-disable-next-line no-prototype-builtins
    if (response.hasOwnProperty(property)) {
      if (response[property] == null) {
        delete response[property];
      }
    }
  }

  Object.assign(mergedData, response);

  dispatch(loginSuccess(mergedData, token));
  dispatch(paginationSuccess(mergedData.nextSkipToken));
};

// LCA metadata actions
export const LCA_METADATA_ADD = 'LCA_METADATA_ADD';
export const LCA_METADATA_DOWNLOAD_BEGIN = 'LCA_METADATA_DOWNLOAD_BEGIN';
export const LCA_METADATA_DOWNLOAD_SUCCESS = 'LCA_METADATA_DOWNLOAD_SUCCESS';
export const LCA_METADATA_DOWNLOAD_ERROR = 'LCA_METADATA_DOWNLOAD_ERROR';

/**
 * Defines an action that updates state to add a LCA to the list of tracked LCA metadata.
 * @param {string} lcaId The id to add to the tracking list.
 * @returns {{lcaId: *, type: *}}
 */
export const lcaMetadataAdd = (lcaId) => ({
  type: LCA_METADATA_ADD,
  lcaId,
});

/**
 * Defines an action that updates state to reflect a LCA metadata download in progress.
 * @param {string} lcaId The id for the LCA data being downloaded.
 * @returns {{lcaId: *, type: *}}
 */
export const lcaMetadataDownloadBegin = (lcaId) => ({
  type: LCA_METADATA_DOWNLOAD_BEGIN,
  lcaId,
});

/**
 * Defines an action that updates state to reflect LCA metadata being successfully downloaded.
 * @param {string} lcaId The id for the LCA that the metadata was downloaded for.
 * @param {Array} fileList The list of files to associate with the LCA.
 * @returns {{lcaId: *, type: *, fileList: *}}
 */
export const lcaMetadataDownloadSuccess = (lcaId, fileList) => ({
  type: LCA_METADATA_DOWNLOAD_SUCCESS,
  lcaId,
  fileList,
});

/**
 * Defines an action that updates state to reflect LCA metadata failing to download.
 * @param {string} lcaId The id for the LCA that failed to download.
 * @param {string} errorMessage The message for the error.
 * @returns {{lcaId: *, errorMessage: *, type: *}}
 */
export const lcaMetadataDownloadError = (lcaId, errorMessage) => ({
  type: LCA_METADATA_DOWNLOAD_ERROR,
  lcaId,
  errorMessage,
});

/**
 * Gets a function that will request LCA metadata.
 * @param {string} lcaId The id for the LCA to request metadata for.
 * @returns {Function} A function that will request LCA metadata.
 */
export const lcaMetadataRequest = (lcaId) => (dispatch) => {
  dispatch(lcaMetadataDownloadBegin(lcaId));

  downloadHandler.requestLcaFileList(
    RequestBuilder.accessToken,
    lcaId,
    (response) => dispatch(lcaMetadataDownloadSuccess(lcaId, response)),
    (error) => dispatch(lcaMetadataDownloadError(lcaId, error)),
  );
};

// PAF metadata actions
export const PAF_METADATA_ADD = 'PAF_METADATA_ADD';
export const PAF_METADATA_DOWNLOAD_BEGIN = 'PAF_METADATA_DOWNLOAD_BEGIN';
export const PAF_METADATA_DOWNLOAD_SUCCESS = 'PAF_METADATA_DOWNLOAD_SUCCESS';
export const PAF_METADATA_DOWNLOAD_ERROR = 'PAF_METADATA_DOWNLOAD_ERROR';

/**
 * Defines an action that updates state to add a PAF to the list of tracked PAF metadata.
 * @param {string} pafId The id to add to the tracking list.
 * @returns {{pafId: *, type: *}}
 */
export const pafMetadataAdd = (pafId) => ({
  type: PAF_METADATA_ADD,
  pafId,
});

/**
 * Defines an action that updates state to reflect a PAF metadata download in progress.
 * @param {string} pafId The id for the PAF data being downloaded.
 * @returns {{pafId: *, type: *}}
 */
export const pafMetadataDownloadBegin = (pafId) => ({
  type: PAF_METADATA_DOWNLOAD_BEGIN,
  pafId,
});

/**
 * Defines an action that updates state to reflect PAF metadata being successfully downloaded.
 * @param {string} pafId The id for the PAF that the metadata was downloaded for.
 * @param {Array} fileList The list of files to associate with the PAF.
 * @returns {{pafId: *, type: *, fileList: *}}
 */
export const pafMetadataDownloadSuccess = (pafId, fileList) => ({
  type: PAF_METADATA_DOWNLOAD_SUCCESS,
  pafId,
  fileList,
});

/**
 * Defines an action that updates state to reflect PAF metadata failing to download.
 * @param {string} pafId The id for the PAF that failed to download.
 * @param {string} errorMessage The message for the error.
 * @returns {{pafId: *, errorMessage: *, type: *}}
 */
export const pafMetadataDownloadError = (pafId, errorMessage) => ({
  type: PAF_METADATA_DOWNLOAD_ERROR,
  pafId,
  errorMessage,
});

/**
 * Gets a function that will request PAF metadata.
 * @param {string} pafId The id for the PAF to request metadata for.
 * @returns {Function} A function that will request PAF metadata.
 */
export const pafMetadataRequest = (pafId) => (dispatch) => {
  dispatch(pafMetadataDownloadBegin(pafId));

  if (RequestBuilder.accessToken.indexOf('-') === 0) {
    // Ignore idToken because this is a simulated request.
    downloadHandler.requestPafFileList(
      RequestBuilder.accessToken,
      '',
      pafId,
      (response) => dispatch(pafMetadataDownloadSuccess(pafId, response)),
      (error) => dispatch(pafMetadataDownloadError(pafId, error)),
    );

    return;
  }

  downloadHandler.requestIdToken(
    (idToken) => {
      downloadHandler.requestPafFileList(
        RequestBuilder.accessToken,
        idToken,
        pafId,
        (response) => dispatch(pafMetadataDownloadSuccess(pafId, response)),
        (error) => dispatch(pafMetadataDownloadError(pafId, error)),
      );
    },
    (error) => dispatch(pafMetadataDownloadError(pafId, error)),
  );
};

// File request actions
export const FILE_REQUEST_BEGIN = 'FILE_REQUEST_BEGIN';
export const FILE_REQUEST_SUCCESS = 'FILE_REQUEST_SUCCESS';
export const FILE_REQUEST_ERROR = 'FILE_REQUEST_ERROR';

/**
 * Defines an action that updates state to reflect a file request starting.
 * @param {string} category The type of file being requested.
 * @param {string} fileId The ID for the file being requested.
 * @param {string} fileName The name of the file being requested.
 * @returns {{
 *  category: string,
 *  fileId: string,
 *  fileName: string,
 *  type: *
 * }}
 */
export const fileRequestBegin = (category, fileId, fileName, location) => ({
  type: FILE_REQUEST_BEGIN,
  category,
  fileId,
  fileName,
  location
});

/**
 * Defines an action that updates state to reflect a file successfully downloading.
 * @param {string} category The type of file that was downloaded.
 * @param {string} fileId The ID for the file that was downloaded.
 * @param {string} fileName The name of the file that was downloaded.
 * @returns {{
 *  category: string,
 *  fileId: string,
 *  fileName: string,
 *  type: *
 * }}
 */
export const fileRequestSuccess = (category, fileId, fileName) => ({
  type: FILE_REQUEST_SUCCESS,
  category,
  fileId,
  fileName,
});

/**
 * Defines an action that updates state to reflect a file failing to download.
 * @param {string} category The type of file that failed to download.
 * @param {string} fileId The ID for the file that failed to download.
 * @param {string} fileName The name of the file that failed to download.
 * @returns {{
 *  category: string,
 *  fileId: string,
 *  fileName: string,
 *  type: *
 * }}
 */
export const fileRequestError = (category, fileId, fileName) => ({
  type: FILE_REQUEST_ERROR,
  category,
  fileId,
  fileName,
});

/**
 * Gets a function that will perform a file download.
 * @param {string} fileUrl The url for the file to download.
 * @param {string} category The type of file to be downloaded.
 * @param {string} fileId The ID for the file to be downloaded.
 * @param {string} filename The name of the file to be downloaded.
 * @returns {Function} A function that will perform a PAF file download.
 */
export const requestFile = (fileUrl, category, fileId, filename, location) => (dispatch) => {
  dispatch(fileRequestBegin(category, fileId, filename, location));

  if (RequestBuilder.accessToken.indexOf('-') === 0) {
    // Ignore idToken and set a fake wait time because this is a simulated call.
    setTimeout(() => {
      downloadHandler.requestFile(
        fileUrl,
        filename,
        () => dispatch(fileRequestSuccess(category, fileId, filename)),
        () => {
          dispatch(errorPromptTrigger(`An error occurred while downloading the file ${filename}`));
          dispatch(fileRequestError(category, fileId, filename));
        },
      );
    },
    1000);

    return;
  }

  downloadHandler.requestFile(
    fileUrl,
    filename,
    () => dispatch(fileRequestSuccess(category, fileId, filename)),
    () => {
      dispatch(errorPromptTrigger(`An error occurred while downloading the file ${filename}`));
      dispatch(fileRequestError(category, fileId, filename));
    },
  );
};

/**
 * Gets a function that will perform a PAF file download.
 * @param {string} fileUrl The url for the file to download.
 * @param {string} category The type of file to be downloaded.
 * @param {string} pafId The id of the PAF associated with the file to download.
 * @param {string} filename The name of the file to be downloaded.
 * @returns {Function} A function that will perform a PAF file download.
 */
export const requestAuthenticatedFile = (fileUrl, category, pafId, filename) => (dispatch) => {
  dispatch(fileRequestBegin(category, pafId, filename));

  if (RequestBuilder.accessToken.indexOf('-') === 0) {
    // Ignore idToken and set a fake wait time because this is a simulated call.
    setTimeout(() => {
      downloadHandler.requestAuthenticatedFile(
        fileUrl,
        '',
        filename,
        () => dispatch(fileRequestSuccess(category, pafId, filename)),
        () => {
          dispatch(errorPromptTrigger(`An error occurred while downloading the file ${filename}`));
          dispatch(fileRequestError(category, pafId, filename));
        },
      );
    },
    1000);

    return;
  }

  downloadHandler.requestIdToken(
    (idToken) => {
      downloadHandler.requestAuthenticatedFile(
        fileUrl,
        idToken,
        filename,
        () => dispatch(fileRequestSuccess(category, pafId, filename)),
        () => {
          dispatch(errorPromptTrigger(`An error occurred while downloading the file ${filename}`));
          dispatch(fileRequestError(category, pafId, filename));
        },
      );
    },
    () => dispatch(fileRequestError(category, pafId, filename)),
  );
};

/**
 * Gets a function that will perform a PAF file download.
 * @param {string} fileUrl The url for the file to download.
 * @param {string} category The type of file to be downloaded.
 * @param {string} pafId The id of the PAF associated with the file to download.
 * @param {string} filename The name of the file to be downloaded.
 * @returns {Function} A function that will perform a PAF file download.
 */
export const requestUnauthenticatedFile = (fileUrl, category, pafId, filename) => (dispatch) => {
  dispatch(fileRequestBegin(category, pafId, filename));

  downloadHandler.requestIdToken(
    (idToken) => {
      downloadHandler.requestAuthenticatedFile(
        fileUrl,
        idToken,
        filename,
        () => dispatch(fileRequestSuccess(category, pafId, filename)),
        () => {
          dispatch(errorPromptTrigger(`An error occurred while downloading the file ${filename}`));
          dispatch(fileRequestError(category, pafId, filename));
        },
      );
    },
    () => dispatch(fileRequestError(category, pafId, filename)),
  );
};

/**
 * Dismisses the error notification prompt.
 * @param {object} event Information about the event that triggered this function.
 * @param {string} reason A string describing the reason this function was triggered.
 * @returns {Function} A function that will dismiss the error notification prompt.
 */
export const clearFileDownloadErrorPrompt = (event, reason) => (dispatch) => {
  if (reason === 'clickaway') {
    return;
  }

  dispatch(errorPromptClear());
};

// Error prompt actions
export const ERROR_PROMPT_TRIGGER = 'ERROR_PROMPT_TRIGGER';
export const ERROR_PROMPT_CLEAR = 'ERROR_PROMPT_CLEAR';

/**
 * Defines an action that updates state to reflect an error prompt triggering.
 * @param {string} errorMessage The message to display in the error prompt.
 * @returns {{type: *, errorMessage: string}}
 */
export const errorPromptTrigger = (errorMessage) => ({
  type: ERROR_PROMPT_TRIGGER,
  errorMessage,
});

/**
 * Defines an action that updates state to reflect an error prompt being cleared.
 * @returns {{type: *}}
 */
export const errorPromptClear = () => ({
  type: ERROR_PROMPT_CLEAR,
});

// Pagination actions
export const SET_PAGINATION_CONTEXT = 'SET_PAGINATION_CONTEXT';
export const PAGINATION_SUCCESS = 'PAGINATION_SUCCESS';
export const PAGINATION_RESET = 'PAGINATION_RESET';
export const PAGE_PROGRESS_BEGIN = 'PAGE_PROGRESS_BEGIN';

/**
 * Defines an action that updates state to reflect the pagination context.
 * @returns {{paginationContext: *}}
 */
export const setPaginationContext = (paginationContext) => ({
  type: SET_PAGINATION_CONTEXT,
  ...paginationContext
});

/**
 * Defines an action that updates state with login success information.
 * @param {string} nextSkipToken The data returned by the login.
 * @param {string} currentSkipToken The current skip token.
 * @returns {{currentSkipToken: *, nextSkipToken: *}}
 */
export const paginationSuccess = (nextSkipToken, paginated) => ({
  type: PAGINATION_SUCCESS,
  nextSkipToken,
  paginated
});

/**
 * Defines an action that starts progressing the data to retrieve.
 * @returns {{type: *}}
 */
export const pageProcessingBegin = () => ({
  type: PAGE_PROGRESS_BEGIN
});

/**
 * Defines an action that resets the state.
 * @returns {{type: *}}
 */
export const paginationReset = () => ({
  type: PAGINATION_RESET
});