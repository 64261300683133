import { connect } from 'react-redux';
import { clearFileDownloadErrorPrompt, loginReset } from '../actions';
import DisplayPage from '../components/DisplayPage';

/**
 * Gets the props to send to the wrapped component.
 * @param {{
 *   clientData: {
 *     clientJson: Object,
 *   },
 *   errorPrompt: {
 *     errorPromptOpen: boolean,
 *     errorMessage: string,
 *     errorTime: number,
 *   }
 * }} state The current state.
 * @returns {{pageData: Object}} The props to supply to the wrapped component.
 */
const mapStateToProps = (state) => ({
  pageData: state.clientData.clientJson,
  errorInfo: state.errorPrompt,
});

/**
 * Gets the functions to send to the wrapped component.
 * @param {function} dispatch A function that wraps another function in Redux state flow.
 * @returns {{
 *   resetClick: (function(): *),
 *   clearErrorPrompt: (function(Object=, string=): *)
 * }} The functions to supply to the wrapped component.
 */
const mapDispatchToProps = (dispatch) => ({
  resetClick: () => {
    sessionStorage.clear();
    return dispatch(loginReset());
  },
  clearErrorPrompt: (event, reason) => dispatch(clearFileDownloadErrorPrompt(event, reason)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DisplayPage);
