import {
  arrayOf, exact, number, string, array, bool,
} from 'prop-types';

export const fileDataType = exact({
  downloadUrl: string.isRequired,
  displayName: string.isRequired,
});

export const lcaMetadataDataType = exact({
  fileListDownloading: bool,
  files: arrayOf(fileDataType),
  fileListDownloadErrorMessage: string,
  lcaId: string.isRequired,
});

export const lcaListingsDataType = arrayOf(exact({
  fileId: string.isRequired,
  lcaNumber: string.isRequired,
  jobTitle: string.isRequired,
  fileNumber: string.isRequired,
  postingDate: string.isRequired,
  location: string,
  operatingCompany: string,
  downloadUrl: string.isRequired
}));

export const nofListingsDataType = arrayOf(exact({
  jobTitle: string.isRequired,
  postingDate: string.isRequired,
  expiredDate: string.isRequired,
  fileId: string.isRequired,
  downloadUrl: string.isRequired,
}));

export const pafListingsDataType = arrayOf(exact({
  fileNumber: string.isRequired,
  jobTitle: string.isRequired,
  expiredDate: string,
  postingDate: string.isRequired,
  pafExpiryDate: string.isRequired,
  lcaNumber: string.isRequired,
  location: string,
  operatingCompany: string,
  fileId: string.isRequired,
  files: array.isRequired,
}));

export const postingMetadataDataType = exact({
  fileListDownloading: bool,
  files: arrayOf(fileDataType),
  fileListDownloadErrorMessage: string,
  lcaId: string,
  pafId: string,
});

export const errorPromptDataType = exact({
  errorPromptOpen: bool,
  errorMessage: string,
  errorTime: number,
});

export const clientInfoDataType = exact({
  pageHeaderLogo: string,
  companyName: string.isRequired,
  titleBackground: string.isRequired,
  pageIntro: string,
  jobListings: lcaListingsDataType,
  nofListings: nofListingsDataType,
  companyMemo: string,
  pafIntro: string,
  pageIntroForNofListings: string,
  pafListings: pafListingsDataType,
  pafMemo: string,
  nextSkipToken: string
});
