import * as PropTypes from 'prop-types';
import React from 'react';

/**
 * A company logo or name to appear in the header.
 * @param {string | null} pageHeaderLogo The url for the header logo.
 * @param {string | null} companyName The company name to display if no logo is provided.
 * @returns {JSX} A UI element that displays company branding info in the header.
 * @constructor
 */
export default function HeaderLogo({ pageHeaderLogo, companyName }) {
  if (pageHeaderLogo) {
    return <img src={pageHeaderLogo} alt={companyName} width="200" />;
  }
  return <h1 className="pageHeaderTitle">{companyName}</h1>;
}
HeaderLogo.propTypes = {
  pageHeaderLogo: PropTypes.string,
  companyName: PropTypes.string.isRequired,
};
HeaderLogo.defaultProps = {
  pageHeaderLogo: null,
};
