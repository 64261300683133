import React from 'react';
import { Box, Card } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import LoginCardBody from './LoginCardBody';

/**
 * Builds the login portal.
 * @param loginBranding {Object} An object containing branding information for the login page.
 * @return {jsx} A login portal component.
 */
const LoginPortal = ({ loginBranding }) => (
  <div className="loginPage">
    <Card className="loginPageAnchor">
      <Box className="loginPortalBody">
        <LoginCardBody loginBranding={loginBranding} />
      </Box>
    </Card>
  </div>
);
LoginPortal.propTypes = {
  loginBranding: PropTypes.shape({
    loginLogo: PropTypes.string,
  }),
};
LoginPortal.defaultProps = {
  loginBranding: null,
};

export default LoginPortal;
