import { getFakePafFileList } from '../helpers/mockJsonData';
import RequestBuilder from '../helpers/requestBuilder';

/**
 * Formats company data from the API definition to the local definition.
 * @param pafId {string} The ID fo the PAF associated with this data.
 * @param responseJson {Object} Company data object from API.
 * @return {{
 *   displayName: string,
 *   downloadUrl: string
 * }[]} A formatted company data object.
 */
const convertResponse = (pafId, responseJson) => {
  // Left side is local name, right side is expected API name
  const pafFileListDataMap = [
    ['displayName', 'Name'],
  ];

  const formattedPafFileList = [];

  responseJson.forEach((file) => {
    const formattedFile = {};

    // Copy values based on the mapping, but only if property is defined.
    for (let index = 0; index < pafFileListDataMap.length; index += 1) {
      if (file[pafFileListDataMap[index][1]]) {
        formattedFile[pafFileListDataMap[index][0]] = file[pafFileListDataMap[index][1]];
      }
    }

    formattedFile.downloadUrl = RequestBuilder.buildPafFileRequestUrl(
      isNaN(Number.parseInt(pafId, 10)) ? file.id : pafId,
      formattedFile.displayName,
      formattedFile.requiresAuth = true,
    );

    formattedPafFileList.push(formattedFile);
  });

  return formattedPafFileList;
};

/**
 * Removes characters from a string that are not permitted.
 * @param accessCode {string} A string of user input.
 * @return {string} A string with only permitted characters included.
 */
const sanitizeInput = (accessCode) => accessCode?.replace(/[^0-9a-z-]/gi, '');

/**
 * Handles processing for successful responses.
 * @param response {Response} A successful response.
 * @param pafId {string} The ID of the PAF related to this data.
 * @param resolve {function(*=): *} Function to execute on success.
 * @param reject {function(*=): *} Function to execute on failure/error.
 */
const handleSuccess = (response, pafId, resolve, reject) => {
  response.json().then((responseJson) => {
    if (responseJson) {
      resolve(convertResponse(pafId, responseJson));
    }
  }).catch(() => reject('An error occurred while requesting the file list.'));
};

/**
 * Handles a request for the file list for a PAF.
 * @param accessCode {string} The access code to submit with the request.
 * @param idToken {string} The authorization token to use for the request.
 * @param pafId {string} The ID of the PAF to request information for.
 * @param resolve {function(*=): *} Function to execute on success.
 * @param reject {function(*=): *} Function to execute on failure/error.
 * @return {undefined} This function does not return a value.
 */
const requestPafFileList = (accessCode, idToken, pafId, resolve, reject) => {
  const sanitizedCode = sanitizeInput(accessCode);

  if (sanitizedCode.indexOf('-') === 0) {
    getFakePafFileList(accessCode)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
    return;
  }

  const request = RequestBuilder.buildPafFileListRequestObject(pafId);
  request.headers.Authorization = `Bearer ${idToken}`;

  fetch(request.url, request)
    .then((response) => {
      if (response.ok) {
        handleSuccess(response, pafId, resolve, reject);
      } else {
        reject('An error occurred while requesting the file list.');
      }
    }).catch((error) => {
      reject(error.message);
    });
};

export default requestPafFileList;
