import { getFakeJson } from '../helpers/mockJsonData';
import jsonRequest from './asyncRequestHandler';
import RequestBuilder from '../helpers/requestBuilder';

/**
 * Handles a request for LCA data.
 * @param accessCode {string} The access code to submit with the request.
 * @param resolve {function(*=): *} Function to execute on success.
 * @param reject {function(*=): *} Function to execute on failure/error.
 * @return {undefined} This function does not return a value.
 */
const requestClientData = (accessCode, paginationContext, resolve, reject) => {
  if (accessCode.indexOf('-') === 0) {
    getFakeJson(accessCode)
      .then((response) => resolve(response))
      .catch(() => {
        reject('An unknown error occurred when communicating with the server.');
      });
    return;
  }

  const request = RequestBuilder.buildClientDataRequestObject(accessCode, paginationContext);

  jsonRequest(request, resolve, reject);
};

export default requestClientData;
