import React from 'react';
import { Button, Card } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import 'react-tabs/style/react-tabs.css';
import DisplayPageBody from './DisplayPageBody';
import HeaderLogo from './HeaderLogo';
import ErrorPrompt from './ErrorPrompt';
import { clientInfoDataType, errorPromptDataType } from './PropDefinitions';

/**
 * Builds the main content display page.
 * @param {{
 *   pageHeaderLogo: string,
 *   companyName: string,
 *   titleBackground: string,
 *   pageIntro: string,
 * }} pageData The data to populate the page with.
 * @param {{
 *     errorPromptOpen: boolean,
 *     errorMessage: string,
 *     errorTime: number,
 *   }} errorInfo Information for the error prompt component.
 * @param {Function} resetClick The function to call when the user logs out.
 * @param {
 *   function(Object=, string=): *
 * } clearErrorPrompt The function to call to dismiss the error prompt.
 * @return {undefined} this function does not return a value.
 */
const DisplayPage = ({
  pageData, errorInfo, resetClick, clearErrorPrompt,
}) => (
  <>
    <div className="lcaDisplayPage">
      <Card className="lcaDisplayCard">
        <span className="pageHeader pageMargins">
          <span className="headerLogo">
            <HeaderLogo
              pageHeaderLogo={pageData.pageHeaderLogo}
              companyName={pageData.companyName}
            />
          </span>
          <span className="headerLinks">
            <Button className="headerLink logout" onClick={resetClick}>
              Log Out
            </Button>
          </span>
        </span>
        <div
          className="pageTitleSection"
          style={{ backgroundImage: `url(${pageData.titleBackground})` }}
        >
          <div className="pageTitleTextBlock">
            <h1 className="pageTitle">{pageData.companyName}</h1>
            <h1 className="pageSubTitle">Postings Portal</h1>
          </div>
        </div>
        <div className="pageBody pageMargins">
          <div className="bodyContent">
            <DisplayPageBody bodyData={pageData} />
          </div>
        </div>
      </Card>
    </div>
    <ErrorPrompt
      errorInfo={errorInfo}
      closeHandler={clearErrorPrompt}
    />
  </>
);
DisplayPage.propTypes = {
  pageData: clientInfoDataType.isRequired,
  errorInfo: errorPromptDataType.isRequired,
  resetClick: PropTypes.func.isRequired,
  clearErrorPrompt: PropTypes.func.isRequired,
};

export default DisplayPage;
