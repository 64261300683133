import { connect } from 'react-redux';
import AuthenticatedTabbedBody from '../components/AuthenticatedTabbedBody';
import { loadPostings } from '../actions';

/**
 * Gets the props to send to the wrapped component.
 * @param {{
 *   bodyData:Object     
 * }} state The current state.
 * @returns {{bodyData: Object}} The props to supply to the wrapped component.
 */
const mapStateToProps = (state, ownProps) => ({
  bodyData: ownProps.bodyData,
  limitRows: state.paginationContext.limitRows
});

/**
 * Gets the functions to send to the wrapped component.
 * @param {function} dispatch A function that wraps another function in Redux state flow.
 * @returns {{
 *   getPostings: (function(string=, Object=): *)
 * }} The functions to supply to the wrapped component.
 */
const mapDispatchToProps = (dispatch) => ({  
  getPostings: (token, paginationContext) => dispatch(loadPostings(token, paginationContext))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthenticatedTabbedBody);
