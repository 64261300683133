import { LinearProgress, withStyles } from '@material-ui/core';

/**
 * A pre-branded loading indicator.
 * @type {React.ComponentClass<LinearProgress>}
 */
export default withStyles({
  root: {
    marginTop: '20px',
    marginBottom: '30px',
    height: '20px',
  },
  barColorPrimary: {
    backgroundColor: '#58b228',
  },
  colorPrimary: {
    backgroundColor: '#FFFFFF',
  },
})(LinearProgress);
