import { connect } from 'react-redux';
import LoginForm from '../components/LoginForm';
import { performLogin } from '../actions';

/**
 * Gets the props to send to the wrapped component.
 * @param {{
 *  clientData: {
 *    errorMessage: {
 *      errorMessage: string,
 *      processingLogin: boolean
 *    }
 *  }
 * }} state The current state.
 * @returns {{errorMessage: *, processingLogin: *}} The props to send to the wrapped component.
 */
const mapStateToProps = (state) => ({
  errorMessage: state.clientData.errorMessage,
  processingLogin: state.clientData.processingLogin,
});

/**
 * Gets the functions to supply to the wrapped component.
 * @param {function} dispatch A function that wraps another function in Redux state flow.
 * @returns {{onClick: (function(*=): *)}} The functions to supply to the wrapped component.
 */
const mapDispatchToProps = (dispatch) => ({
  onClick: (token) => dispatch(performLogin(token.toLowerCase())),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginForm);
