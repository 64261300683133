import React from 'react';
import {
  Tab, TabList, TabPanel, Tabs,
} from 'react-tabs';
import LcaGrid from './LcaGrid';
import NofGrid from './NofGrid';
import { clientInfoDataType } from './PropDefinitions';

/**
 * The styles to apply to this component.
 * @type {{iconsAndText: {color: string}}}
 */
const styles = {
  iconsAndText: {
    color: '#6B6B6B',
  },
};

/**
 * Builds a tabbed version of the display page body.
 * @param bodyData {*} The data to populate the page with.
 * @return {jsx} A tabbed version of the display page body.
 */
const NonAuthenticatedTabbedBody = ({ bodyData, limitRows, getPostings }) => {
  const lcaContext = {
    pageContext: "lca",
    limitRows,
    nextSkipToken: null,
    paginated: false
  };
  const nofContext = {
    pageContext: "nof",
    limitRows,
    nextSkipToken: null,
    paginated: false
  };

  const cachedToken = sessionStorage.getItem('cachedAccessToken');
  return (
    <Tabs defaultIndex={0}>
      <br />
      <br />
      <TabList>
        <Tab>
          <Tab onClick={() => getPostings(cachedToken, lcaContext)}>
            <span style={styles.iconsAndText}>
              <i className="fa fa-file-text" />
                &nbsp; Active LCAs
            </span>
          </Tab>
        </Tab>
        <Tab onClick={() => getPostings(cachedToken, nofContext)}>
          <span style={styles.iconsAndText}>
            <i className="fa fa-folder-open">&nbsp;</i>
          &nbsp; Active NOFs
          </span>
        </Tab>
      </TabList>

      <TabPanel>
        <LcaGrid
          lcaPostingRows={bodyData.jobListings}
          introMessage={bodyData.pageIntro}
        />
      </TabPanel>
      <TabPanel>
        <NofGrid
          nofPostingRows={bodyData.nofListings}
          introMessage={bodyData.pageIntroForNofListings}
        />
      </TabPanel>
    </Tabs>
  );
};

NonAuthenticatedTabbedBody.propTypes = {
  bodyData: clientInfoDataType.isRequired,
};

export default NonAuthenticatedTabbedBody;
