import { connect } from 'react-redux';
import { requestAuthenticatedFile } from '../actions';
import PafGrid from '../components/PafGrid';

/**
 * Identifier for downloads initiated by this control.
 * @type {string}
 */
const downloadCategory = 'paf-memo';

/**
 * Gets the props to supply to the wrapped component.
 * @param {{
 *   fileDownload: {
 *     category: string,
 *     entryId: string,
 *     fileName: string,
 *   }[],
 * }} state The current state.
 * @param {{
 *   pafListings: Object[],
 *   introMessage: string,
 *   memoDownloadLink: string,
 * }} ownProps The properties that were submitted to this component.
 * @returns {{
 *   pafListings: Object[],
 *   introMessage: string,
 *   memoDownloadLink: string,
 *   activeDownloadList: {
 *     category: string,
 *     entryId: string,
 *     fileName: string,
 *   }[],
 * }} The props to supply to the wrapped component.
 */
const mapStateToProps = (state, ownProps) => ({
  pafListings: ownProps.pafListings,
  introMessage: ownProps.introMessage,
  memoDownloadLink: ownProps.memoDownloadLink,
  activeDownloadList: state.fileDownload.filter((download) => download.category === downloadCategory),
  errorMessage: state.clientData?.errorMessage
});

/**
 * Gets the functions to supply to the wrapped component.
 * @param {function} dispatch A function that wraps another function in Redux state flow.
 * @returns {{
 *  requestFile: (function(string=, string=, string=): *)
 * }} The functions to supply to the wrapped component
 */
const mapDispatchToProps = (dispatch) => ({
  // eslint-disable-next-line arrow-body-style
  requestFile: (fileUrl, pafId, filename) => {
    return dispatch(requestAuthenticatedFile(fileUrl, downloadCategory, pafId, filename));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PafGrid);
