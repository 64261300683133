import { connect } from 'react-redux';
import { pafMetadataAdd, pafMetadataRequest } from '../actions';
import PafGridBody from '../components/PafGridBody';

/**
 * Gets the props to send to the wrapped component.
 * @param {{pafMetadata: Object[]}} state The current state.
 * @param {{pafListings: Object[]}} ownProps The props provided to this component.
 * @returns {{
 *  pafMetadata: Object[],
 *  pafListings: Object[]
 * }} The props to send to the wrapped component.
 */
const mapStateToProps = (state, ownProps) => ({
  pafListings: ownProps.pafListings,
  pafMetadata: state.pafMetadata,
  progressing: state.paginationContext.progressing
});

/**
 * Gets the functions to supply to the wrapped component.
 * @param {function} dispatch A function that wraps another function in Redux state flow.
 * @returns {{
 *  addPafToPafMetadataList: (function(*=): *),
 *  downloadPafFileMetadata: (function(*=): *)
 * }} The functions to supply to the wrapped component.
 */
const mapDispatchToProps = (dispatch) => ({
  downloadPafFileMetadata: (pafId) => dispatch(pafMetadataRequest(pafId)),
  addPafToPafMetadataList: (pafId) => dispatch(pafMetadataAdd(pafId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PafGridBody);
