import React from 'react';
import * as PropTypes from 'prop-types';
import BrandedCircularLoadingIndicator from './BrandedCircularLoadingIndicator';

/**
 * Displays a download icon or a loading icon depending on download status.
 * @param {bool} downloading True if downloading, otherwise false.
 * @returns {jsx} An icon to accompany a download link.
 * @constructor
 */
const DownloadIcon = ({ downloading }) => {
  if (downloading) {
    return <BrandedCircularLoadingIndicator size={25} />;
  }
  return <i className="fa fa-download" />;
};
DownloadIcon.propTypes = {
  downloading: PropTypes.bool.isRequired,
};

export default DownloadIcon;
