import convertResponse from '../helpers/dataFormatter';

/**
 * Processes a successful response
 * @param {Response} response A successful response.
 * @param {Function} resolve The function to call if the request succeeds.
 * @param {Function} reject The function to call if the request fails.
 */
const handleSuccess = (response, resolve, reject) => {
  response.json().then((responseJson) => {
    if (responseJson) {
      resolve(convertResponse(responseJson));
    }
  }).catch(() => {
    reject('An unknown error occurred when communicating with the server.');
  });
};

/**
 * Performs an asynchronous request for JSON data.
 * @param {Request} request The request to process.
 * @param {Function} resolve The function to call if the request succeeds.
 * @param {Function} reject The function to call if the request fails.
 */
export default function jsonRequest(request, resolve, reject) {
  fetch(request.url, request)
    .then((response) => {
      if (response.ok) {
        handleSuccess(response, resolve, reject);
      } else if (response.status === 401 || response.status === 404) {
        reject('Access not authorized.');
      } else {
        reject('An unknown error occurred when communicating with the server.');
      }
    })
    .catch(() => {
      reject('An unknown error occurred when communicating with the server.');
    });
}
