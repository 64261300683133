import React from 'react';
import * as PropTypes from 'prop-types';
import WrappedLcaGridBody from '../containers/WrappedLcaGridBody';
import { lcaListingsDataType } from './PropDefinitions';

/**
 * Builds the grid of the LCA page.
 * @param classes {Object} contains a collection of class styles to use.
 * @param lcaPostingRows {Array} The array to populate the grid with.
 * @param introMessage {string} The message to display before the table body.
 * @return {undefined} This function does not return a value.
 */
const LcaGrid = ({
  lcaPostingRows, introMessage,
}) => { 
  return (
    <>
      <p style={{ color: '#6B6B6B' }}>{introMessage}</p>      
      <WrappedLcaGridBody rows={lcaPostingRows} />      
    </>
  );
};
LcaGrid.propTypes = {  
  lcaPostingRows: lcaListingsDataType.isRequired,
  introMessage: PropTypes.string.isRequired,
};

export default (LcaGrid);
