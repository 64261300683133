import React from 'react';
import * as PropTypes from 'prop-types';
import { Container, Link } from '@material-ui/core';
import BrandedLinearLoadingIndicator from './BrandedLinearLoadingIndicator';
import DownloadIcon from './DownloadIcon';
import { postingMetadataDataType } from './PropDefinitions';

/**
 * Styling options for this component.
 * @type {{
 *  documents: {paddingLeft: string},
 *  documentListMessage: {paddingLeft: string},
 *  downloadingIndicator: {
 *    marginBottom: string,
 *    marginTop: string,
 *    height: string
 *  }
 * }}
 */
const styles = {
  fileName: {
    fontSize: '16px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  downloadLink: {
    fontSize: '12px',
    color: 'grey',
    verticalAlign: 'text-bottom',
    marginRight: '8px'
  },
  documents: {
    paddingLeft: '60px',
  },
  documentListMessage: {
    paddingLeft: '30px',
  },
  downloadingIndicator: {
    marginTop: '20px',
    marginBottom: '30px',
    height: '20px',
  },
  listStyle: {
    display: 'flex'
  }
};

/**
 * Checks if a given file is among the active downloads.
 * @param {string} id The ID associated with the download.
 * @param {string} fileName The filename associated with the download.
 * @param {{
 *   category: string,
 *   fileId: string,
 *   fileName: string,
 * }[]} activeDownloadList A collection of the current file downloads.
 * @returns {boolean} True if the file is currently downloading, otherwise false.
 */
// eslint-disable-next-line arrow-body-style
const isDownloading = (id, fileName, activeDownloadList) => {
  // eslint-disable-next-line arrow-body-style
  return activeDownloadList.some((file) => {
    return file.fileId === id && file.fileName === fileName;
  });
};

/**
 * Display a list of documents or the progress of downloading a list of documents for a PAF.
 * @param {{
 *   fileListDownloading: bool,
 *   files: {
 *     downloadUrl: string,
 *     displayName: string,
 *   }[],
 *   fileListDownloadErrorMessage: string,
 *   pafId: string,
 * } | null} fileMetadata An object containing data for the file list.
 * @param {{
 *   category: string,
 *   fileId: string,
 *   fileName: string,
 * }[]} activeDownloadList A list of files actively being downloaded.
 * @param {function} requestFile The function to call when requesting a file.
 * @return {JSX.Element} Document information for a PAF
 */
const DocumentList = ({ fileMetadata, activeDownloadList, requestFile }) => {
  if (fileMetadata.fileListDownloading) {
    return (
      <Container>
        <p>
          Retrieving file list...
        </p>
        <BrandedLinearLoadingIndicator />
      </Container>
    );
  }

  if (fileMetadata.fileListDownloadErrorMessage) {
    const errorMessage = `Error Occurred: ${fileMetadata.fileListDownloadErrorMessage}`;

    return (
      <Container>
        <p>{errorMessage}</p>
      </Container>
    );
  }

  if (fileMetadata.files.length === 0) {
    return (
      <Container>
        <p>No files found for this entry.</p>
      </Container>
    );
  }

  let message = 'Below are the attachments added to this Public Access File';
  if (!fileMetadata.files[0].requiresAuth) {
    message = 'Below are the attachments added to this Labor Condition Application';
  }

  return (
    <div>
      <p style={styles.documentListMessage}>
        {message}
      </p>
      <div style={styles.iconsAndText}>
        <ul className="docsDisplay">
          {fileMetadata.files.map((fileInfo) => {
            const { displayName, downloadUrl, requiresAuth } = fileInfo;
            const { pafId } = fileMetadata;
            const downloadInProgress = isDownloading(pafId, displayName, activeDownloadList);

            let onClickHandler = () => { requestFile(downloadUrl, pafId, displayName, requiresAuth); };

            if (downloadInProgress) {
              // Do nothing on click if download is active.
              onClickHandler = () => {};
            }

            return (
              <li style = {styles.listStyle} key={displayName}>
                <span style={styles.fileName}>
                  <i className="fa fa-file-text" />
                  &nbsp; &nbsp;
                  {displayName}
                </span>
                &nbsp; &nbsp;
                <Link
                  component="button"
                  rel="noopener noreferrer"
                  onClick={() => onClickHandler()}
                  style={styles.downloadLink}
                >
                  <div className = "downloadTextAlign">
                    <span style={styles.downloadLink}>
                      Download                    
                    </span>
                    <DownloadIcon downloading={downloadInProgress} />
                  </div>                  
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
DocumentList.propTypes = {
  fileMetadata: postingMetadataDataType.isRequired,
  activeDownloadList: PropTypes.arrayOf(PropTypes.object).isRequired,
  requestFile: PropTypes.func.isRequired,
};

export default DocumentList;
