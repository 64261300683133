import {
  FILE_REQUEST_BEGIN,
  FILE_REQUEST_SUCCESS,
  FILE_REQUEST_ERROR,
} from '../actions';

/**
 * Returns a new state depending on the provided action.
 * @param {{
 *  category: string,
 *  fileId: string,
 *  fileName: string,
 * }[]} state The current state.
 * @param {{
 *   type: string,
 *   category: string,
 *   fileId: string,
 *   fileName: string,
 * }} action An object containing parameters and the action to perform.
 * @returns {{
 *  category: string,
 *  fileId: string,
 *  fileName: string,
 * }[]} The new state to use.
 */
const fileDownload = (state = [], action) => {
  switch (action.type) {
    case FILE_REQUEST_BEGIN:
      return [
        ...state,
        {
          category: action.category,
          fileId: action.fileId,
          fileName: action.fileName,
          location: action.location
        },
      ];
    case FILE_REQUEST_SUCCESS:
    case FILE_REQUEST_ERROR:
      return state.filter((file) => doesDownloadEntryMatch(file, action));
    default:
      return state;
  }
};

/**
 * Checks if two download entries match
 * @param {{
 *  category: string,
 *  fileId: string,
 *  fileName: string,
 * }} firstEntry The first entry to compare.
 * @param {{
 *  category: string,
 *  fileId: string,
 *  fileName: string,
 * }} secondEntry The second entry to compare.
 * @returns {boolean} True if entries match, otherwise false.
 */
function doesDownloadEntryMatch(firstEntry, secondEntry) {
  return firstEntry.category !== secondEntry.category &&
         firstEntry.fileId !== secondEntry.fileId &&
         firstEntry.fileName !== secondEntry.fileName;
}

export default fileDownload;
