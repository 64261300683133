import React from 'react';
import * as PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import BrandedCircularLoadingIndicator from './BrandedCircularLoadingIndicator';

/**
 * Creates a table cell meant for displaying download links with a graphic displayed.
 * @param {function(*=): *} requestFile A function that performs a file request.
 * @param {boolean} downloadInProgress Whether the file download is already in progress.
 * @return {jsx} A table cell with a download link inside.
 */
function PostingsDownloadLinkTableCell({ requestFile, downloadInProgress }) {
  if (downloadInProgress) {
    return <BrandedCircularLoadingIndicator />;
  }

  return (   
    <div>
      <IconButton style={{ color: '#6B6B6B', width: '40px', height: '40px'}} onClick={() => requestFile()}>
        <i className="fa fa-download" />
      </IconButton>    
    </div> 
  );
}
PostingsDownloadLinkTableCell.propTypes = {
  requestFile: PropTypes.func.isRequired,
  downloadInProgress: PropTypes.bool.isRequired,
};

export default PostingsDownloadLinkTableCell;
