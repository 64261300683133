import {
  SET_PAGINATION_CONTEXT, PAGINATION_SUCCESS, PAGINATION_RESET, PAGE_PROGRESS_BEGIN
} from '../actions';

/**
 * Returns a new state depending on the provided action.
 * @param {{
 *  previousSkipToken: null,
 *  nextSkipToken: null,
 *  pageContext: all/lca/paf,
 *  limitRows: 20,
 *  errorMessage: string
 * }} state The current state.
 * @param {{
 *   previousSkipToken: string,
 *   nextSkipToken: string,
 *   pageContext: string,
 *   errorMessage: string,
 * }} action An object containing parameters and the action to perform.
 * @returns {{
 *  previousSkipToken: string | null,
 *  nextSkipToken: Object | null,
 *  pageContext: all/lca/paf,
 *  errorMessage: string | null,
 *  limitRows: int
 * }} The new state to apply.
 */
const paginationContext = (state = defaultState(), action) => {
  switch (action.type) {
    // Updates state to set pagination context.
    case SET_PAGINATION_CONTEXT:
      return {
        ...state,
        pageContext: action.pageContext,
        nextSkipToken: action.nextSkipToken,
        paginated: action.paginated,
        errorMessage: null
      };
    // Updates state with data from successful page navigation.
    case PAGINATION_SUCCESS:
      return {
        ...state,
        nextSkipToken: action.nextSkipToken,
        paginated: action.paginated,
        progressing: false
      };
       // Updates state with data from successful page navigation.
    case PAGE_PROGRESS_BEGIN:
      return {
        ...state,
        progressing: true
      };
    // Restores the default state.
    case PAGINATION_RESET:
      return defaultState();
    // Makes no changes to the state.
    default:
      return state;
  }
};

/**
 * The default state to use.
 * @returns {{
 *  progressing: false
 *  nextSkipToken: null,
 *  pageContext: all/lca/paf,
 *  limitRows: 20,
 *  paginated: bool
 *  errorMessage: string
 * }} A new instance of the default state.
 */
const defaultState = () => ({
  progressing: false,
  nextSkipToken: null,
  pageContext: 'lca',
  limitRows: 20,
  paginated: false,
  errorMessage: null,
});

export default paginationContext;
