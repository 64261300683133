import React from 'react';
import BrandedCircularLoadingIndicator from './BrandedCircularLoadingIndicator';

/**
 * Builds the loading circle to display when logging in.
 * @return {jsx} A loading indicator component.
 */
export default () => (
  <div className="loadingCircleContainer">
    <BrandedCircularLoadingIndicator size={100} />
    <p>We are fetching your data, this might take a minute.</p>
  </div>
);
