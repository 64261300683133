import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { TableBody, TableRow, withStyles } from '@material-ui/core';
import PostingTableCell from './PostingTableCell';
import OpenFileListTableCell from './OpenFileListTableCell';
import { pafListingsDataType, postingMetadataDataType } from './PropDefinitions';
import PageLoadingIndicator from './PageLoadingIndicator';
import Paper from '@material-ui/core/Paper';
import { Grid, Table, TableHeaderRow, PagingPanel, TableFilterRow, TableGroupRow,
  GroupingPanel, DragDropProvider, Toolbar, SearchPanel } from '@devexpress/dx-react-grid-material-ui';
import { PagingState, IntegratedPaging, FilteringState, IntegratedFiltering,
 GroupingState, IntegratedGrouping, SearchState } from '@devexpress/dx-react-grid';

/**
 * Gets the styles to apply to this component.
 * @returns {{
 *  tableStriped: {
 *    color: string,
 *    '& tbody tr:nth-of-type(odd)': {
 *      backgroundColor: string
 *    }
 *  },
 *  customCell: {
 *    whiteSpace: string,
 *    width: string,
 *    marginTop: theme.spacing(3),
 *    overflowX: string,
 *    fontSize: int,
 *    color: string,
 *    fontFamily: string
 *  },
 *  customHeaderCell: {
 *    whiteSpace: string,
 *    height: string,
 *    backgroundColor: string,
 *    overflowX: string,
 *    fontSize: int,
 *    color: string,
 *    fontFamily: string
 *  }
 * }}
 */
const styles = (theme) => ({  
  customCell: {
    whiteSpace: "normal",
    width: '100%',
    marginTop: theme.spacing(1),
    overflowX: 'auto',
    fontSize: 12,
    padding: '8px',
    color: '#6B6B6B',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
  },
  customHeaderCell: {
    whiteSpace: "normal",
    height: 40,
    backgroundColor: '#6B6B6B',
    color: theme.palette.common.white,
    fontSize: 15,
    fontFamily: '"Arial"',
    paddingLeft: 1,
    paddingRight: 2
  },
  tableStriped: {
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: '#f5f5f5',
    },
    color: '#9F9F9F'
  },
});

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />  
);

const CustomHeaderCellBase = ({ classes, ...restProps }) => (  
  <TableHeaderRow.Cell className={classes.customHeaderCell} {...restProps} />
);

const TableComponentBase = ({ classes, ...restProps }) => (
  <Table.Table
    {...restProps}
    className={classes.tableStriped}
  />
);

export const CustomizeTableCell = withStyles(styles)(CustomTableCellBase);
export const CustomizeHeaderCell = withStyles(styles)(CustomHeaderCellBase);
export const TableComponent = withStyles(styles, { name: 'TableComponent' })(TableComponentBase);

/**
 * The grid body to display on the PAF page.
 */
class PafGridBody extends Component {
  /**
   * Sets up initial values when component mounts.
   */
  componentDidMount() {
    const { pafListings, addPafToPafMetadataList } = this.props;

    if (!pafListings) return;

    pafListings.forEach((dataRow) => {
      addPafToPafMetadataList(dataRow.fileId);
    });
  }

  constructor(props) {
    super(props);
    this.state = {      
      filteringStateColumnExtensions: [
        { columnName: '', filteringEnabled: false }
      ],
      groupingStateColumnExtensions: [
        { columnName: '', groupingEnabled: false },
      ]
    }
  }

  /**
   * Renders the component.
   * @returns {jsx} The PAF grid body.
   */
  render() {
    const {
      pafListings, pafMetadata, downloadPafFileMetadata, progressing, addPafToPafMetadataList
    } = this.props;

    const defaultColumnWidths = [
      { columnName: 'lcaNumber', width: 'auto' },
      { columnName: 'jobTitle', width: 'auto' },
      { columnName: 'fileNumber', width: '70px' },
      { columnName: 'postingDate', width: '75px' },
      { columnName: 'postingExpirationDate', width: '75px' },
      { columnName: 'pafExpirationDate', width: '75px' },
      { columnName: 'location', width: 'auto' },
      { columnName: 'operatingCompany', width: 'auto' },
      { columnName: '', width: '45px' }
    ];

    const columns = [
      { name: 'lcaNumber', title: 'LCA Number' },
      { name: 'jobTitle', title: 'Job Title' },
      { name: 'fileNumber', title: 'File Number' },
      { name: 'postingDate', title: 'Posting Date' },
      { name: 'postingExpirationDate', title: 'Posting Exp', getCellValue: row => (row.expiredDate) },
      { name: 'pafExpirationDate', title: 'PAF Exp', getCellValue: row => (row.pafExpiryDate) },
      { name: 'location', title: 'Location', getCellValue: row => (row.location ? row.location : null) },
      { name: 'operatingCompany', title: 'Operating Company',
          getCellValue: row => (row.operatingCompany ? row.operatingCompany : null) },
      { name: '', title: '', 
          getCellValue: row => <OpenFileListTableCell
               lcaNumber={row.lcaNumber}
               jobTitle={row.jobTitle}
               fileId={row.fileId}
               fileMetadata={pafMetadata.find((pafMeta) => pafMeta.pafId === row.fileId)}
               requestPostingFileList={downloadPafFileMetadata} />},
    ];

    let pafEntriesToDisplay = pafListings;
    let pafMetadataToDisplay = pafMetadata;

    if (!pafListings) {
      pafEntriesToDisplay = [];
    } else {
      pafListings.forEach((dataRow) => {
        addPafToPafMetadataList(dataRow.fileId);
      });
    }

    if (!pafMetadata) {
      pafMetadataToDisplay = [];
    }

    if (progressing) {
      return (
        <TableBody className="pageLoadingTable">
          <TableRow>
            <PostingTableCell colSpan={6}>
              <PageLoadingIndicator />
            </PostingTableCell>
          </TableRow>
        </TableBody>);
    }  

    return (
      <Paper>
        <Grid rows = {pafEntriesToDisplay} columns = {columns}>
          <SearchState defaultValue="" />
          <DragDropProvider />
          <GroupingState defaultGrouping={[]} columnExtensions={this.state.groupingStateColumnExtensions} />
          <FilteringState defaultFilters={[]} columnExtensions={this.state.filteringStateColumnExtensions}/>

          <IntegratedFiltering />
          <PagingState defaultCurrentPage={0} defaultPageSize={20} />
          <IntegratedGrouping />
          <IntegratedPaging />          
          
          <Table cellComponent={CustomizeTableCell} tableComponent={TableComponent} columnExtensions={defaultColumnWidths} />          
          <TableHeaderRow cellComponent={CustomizeHeaderCell} />
          <TableGroupRow />
          <TableFilterRow />
          
          <Toolbar />
          <GroupingPanel showGroupingControls />
          <SearchPanel />
          <PagingPanel />
        </Grid>
      </Paper>            
    );
  }
}

PafGridBody.propTypes = {
  pafListings: pafListingsDataType.isRequired,
  pafMetadata: PropTypes.arrayOf(postingMetadataDataType),
  downloadPafFileMetadata: PropTypes.func.isRequired,
  progressing: PropTypes.bool,
  addPafToPafMetadataList: PropTypes.func.isRequired,
};

PafGridBody.defaultProps = {
  pafMetadata: [],
};

export default withStyles(styles)(PafGridBody);
