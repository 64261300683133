import React from 'react';
import BrandedCircularLoadingIndicator from './BrandedCircularLoadingIndicator';

/**
 * Builds the loading circle to display when building the login portal.
 * @return {jsx} A loading indicator component.
 */
export default () => (
  <div className="loadingCircleContainer">
    <BrandedCircularLoadingIndicator size={100} />
  </div>
);
