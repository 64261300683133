export default class RequestBuilder {
  /**
   * Gets prod API information.
   * @returns {{url: string, key: string}}
   */
  static getProdApi = () => ({
    url: 'https://api.eiglaw.com',
    key: '596d3c5b01534942939c5e0811046dbd',
  });

  /**
   * Gets dev API information.
   * @returns {{url: string, key: string}}
   */
  static getDevApi = () => ({
    url: 'https://eigapi-dev.azure-api.net',
    key: '8a685c2f184646f4abe2de49b4f7a874',
  });

  /**
   * A string that can be used to identify a dev URL.
   * @type {string}
   */
  static devString = 'lcapostings-dev';

  /**
   * The path for LCA related data.
   * @type {string}
   */
  static lcaPathParam = '/lca';

  /**
   * The path for LCA List.
   * @type {string}
   */
   static lcaListpathParam = '/lcas';

  /**
   * The path for NoF related data.
   * @type {string}
   */
  static nofPathParam = '/nof';

    /**
   * The path for NOF List.
   * @type {string}
   */
  static nofListpathParam = '/nofs';

  /**
   * Attachment keyword to use in file request URLs.
   * @type {string}
   */
  static attachmentKeyword = '/attachment';

  /**
   * The relative endpoint for requesting authenticated PAF data.
   * @type {string}
   */
  static pafDataPathParam = '/postings';

  /**
   * The relative endpoint for requesting PAF data.
   * @type {string}
   */
  static pafPathParam = '/PAF';

      /**
   * The path for PAF List.
   * @type {string}
   */
  static pafListpathParam = '/pafs';

  

  /**
   * A cached token entered by the user.
   * @type {string}
   */
  static accessToken = '';

  /**
   * Gets API information based on the current URL.
   * @returns {{url: string, key: string}}
   */
  static getApiInformation = () => {
    if (window.location.href.includes(this.devString)) {
      return this.getDevApi();
    }

    return this.getProdApi();
  };

  /**
   * The default parameters to send with a request
   * @type {
   *  function(): {
   *    async: boolean,
   *    headers: {
   *      'cache-control': string,
   *    },
   *    method: string,
   *    crossDomain: boolean
   *  }
   * }
   */
  static getRequestOptions = () => {
    const requestOptionsBase = {
      async: true,
      crossDomain: true,
      method: 'GET',
      headers: {
        'cache-control': 'no-cache',
      },
    };

    const apiKey = this.getApiInformation().key;

    // Strip the API key if it isn't needed.
    if (apiKey && apiKey !== '') {
      requestOptionsBase.headers['Ocp-Apim-Subscription-Key'] = this.getApiInformation().key;
    }

    return requestOptionsBase;
  };

  /**
   *  Gets a request object that requests authenticated PAF client data.
   * @param {string} accessCode The access code for the client to get data for.
   * @returns {
   *  {
   *    async: boolean,
   *    headers: {
   *      'cache-control': string,
   *    },
   *    method: string,
   *    crossDomain: boolean
   *  }
   *}
   */
  static buildAuthenticatedClientDataRequestObject(accessCode, paginationContext) {

    if (accessCode.indexOf('-') > 0 ) {
      var token = '/'.concat(accessCode.slice(accessCode.indexOf('-')+1));
    }
    const requestUrl = this.getApiInformation().url.concat(
      this.lcaPathParam,
      this.pafListpathParam,
      token,
    );

    const apiEndpoint = new URL(requestUrl);    

    const requestObject = this.getRequestOptions();
    requestObject.url = apiEndpoint.href;

    return requestObject;
  }

  /**
   *  Gets a request object that requests client data.
   * @param {string} accessCode The access code for the client to get data for.
   * @returns {
   *  {
   *    async: boolean,
   *    headers: {
   *      'cache-control': string,
   *    },
   *    method: string,
   *    crossDomain: boolean
   *  }
   *}
   */
  static buildClientDataRequestObject(accessCode, paginationContext) {
    accessCode = accessCode.replace('paf-', '');
    var postingsContext = '';
    if (paginationContext.pageContext !== 'lca') {
      postingsContext = this.nofListpathParam;
    }
    else {
      postingsContext = this.lcaListpathParam;
    }
    var token = '/'.concat(accessCode);
    const requestUrl = this.getApiInformation().url.concat(
      this.lcaPathParam,
      postingsContext,
      token,
    );
    const apiEndpoint = new URL(requestUrl);

    const requestObject = this.getRequestOptions();
    requestObject.url = apiEndpoint.href;

    return requestObject;
  }

  /**
   * Gets a URL that requests an LCA file.
   * @param {string} fileId The ID for the desired file.
   * @returns {string} A URL that requests an LCA file.
   */
  static buildLcaFileRequestUrl(fileId, fileName) {
    const requestUrl = this.getApiInformation().url.concat(
      this.lcaPathParam,
      this.lcaPathParam,
    );

    const apiEndpoint = new URL(requestUrl);
    apiEndpoint.searchParams.set('lcaid', fileId);
    apiEndpoint.searchParams.set('name', fileName);

    return apiEndpoint.href;
  }

  /**
   * Gets a URL that requests a NoF file.
   * @param {string} nofFileId The ID string for the NoF file to download.
   * @returns {string} A URL that can be used to request an NoF file.
   */
  static buildNofFileRequestUrl(nofFileId) {
    const requestUrl = this.getApiInformation().url.concat(
      this.lcaPathParam,
      this.nofPathParam,
      '/',
      nofFileId.toString(),
      this.attachmentKeyword,
    )

    const apiEndpoint = new URL(requestUrl);
    return apiEndpoint.href;
  }

  /**
   * Gets a URL that requests a memo file.
   * @param {string} memoId The ID for this memo file.
   * @returns {string} A URL that requests a memo file.
   */
  static buildMemoFileRequestUrl(memoId) {
    const requestUrl = this.getApiInformation().url.concat(
      this.lcaPathParam,
      this.pafPathParam,
    );

    const apiEndpoint = new URL(requestUrl);
    apiEndpoint.searchParams.set('clientid', memoId.toString());

    return apiEndpoint.href;
  }

  /**
   * Gets a request object that requests files relating to a LCA.
   * @param {string} lcaId The ID for the LCA.
   * @returns {
   *  {
   *    async: boolean,
   *    headers: {
   *      'cache-control': string,
   *    },
   *    method: string,
   *    crossDomain: boolean
   *  }
   *}
   */
  static buildLcaFileListRequestObject(lcaId) {
    const requestUrl = this.getApiInformation().url.concat(
      this.lcaPathParam,
      this.lcaPathParam,
    );

    const apiEndpoint = new URL(requestUrl);
    apiEndpoint.searchParams.set('LCAID', lcaId);

    const requestObject = this.getRequestOptions();
    requestObject.url = apiEndpoint.href;

    return requestObject;
  }

  /**
   * Gets a request object that requests files relating to a PAF.
   * @param {string} pafId The ID for the PAF.
   * @returns {
   *  {
   *    async: boolean,
   *    headers: {
   *      'cache-control': string,
   *    },
   *    method: string,
   *    crossDomain: boolean
   *  }
   *}
   */
  static buildPafFileListRequestObject(pafId) {
    const requestUrl = this.getApiInformation().url.concat(
      this.lcaPathParam,
      this.pafPathParam,
    );

    const apiEndpoint = new URL(requestUrl);
    apiEndpoint.searchParams.set('LCAID', pafId);

    const requestObject = this.getRequestOptions();
    requestObject.url = apiEndpoint.href;

    return requestObject;
  }

  /**
   * Gets a URL that requests a file for a PAF.
   * @param {string} pafId The PAF associated with the file being requested.
   * @param {string} fileName The filename for the file being requested.
   * @returns {string} A URL that requests a file for a PAF.
   */
  static buildPafFileRequestUrl(pafId, fileName) {
    const requestUrl = this.getApiInformation().url.concat(
      this.lcaPathParam,
      this.pafPathParam,
    );

    const apiEndpoint = new URL(requestUrl);
    apiEndpoint.searchParams.set('pafid', pafId.toString());
    apiEndpoint.searchParams.set('name', fileName);

    return apiEndpoint.href;
  }

  
  /**
   *  Gets a request object with search params.
   * @param {requestUrl} requestUrl To update the search parameters.
   * @param {paginationContext} paginationContext The context for pagination.
   * @returns {
  *  {
    *    requestUrl: URL,
    *}
    */
  static buildSearchParametersRequestObject(requestUrl, paginationContext) {
    if (paginationContext && paginationContext.limitRows) {
      requestUrl.searchParams.append('limitRows', paginationContext.limitRows);
      if (paginationContext.nextSkipToken) {
        requestUrl.searchParams.append('skipToken', paginationContext.nextSkipToken);
      }
      if (paginationContext.pageContext) {
        requestUrl.searchParams.append('pageContext', paginationContext.pageContext);
      }
    }
  }
}
