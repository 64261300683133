import React from 'react';
import {
  Button, Dialog, DialogActions, Container
} from '@material-ui/core';
import * as PropTypes from 'prop-types';
import WrappedDocumentList from '../containers/WrappedDocumentList';
import BrandedLinearLoadingIndicator from './BrandedLinearLoadingIndicator';

/**
 * Styles to use with this component.
 * @type {{
 *  header: {paddingLeft: string},
 *  iconsAndText: {color: string}
 * }}
 */
const styles = {
  iconsAndText: {
    color: '#6B6B6B',
  },
  header: {
    paddingLeft: '30px',
  },
};

/**
 * Display a list of documents or the progress of downloading a list of documents for a PAF.
 * @param title {string} Title for the modal.
 * @param jobTitle {string} Job title to display.
 * @param open {bool} Whether or not the modal is open.
 * @param fileMetadata {null | object} An object containing data for the file list.
 * @param toggleExpand {function} The function to call to close the modal.
 * @return {JSX} Document information for a PAF
 */
const DocumentsModal = ({
  title, jobTitle, open, fileMetadata, toggleExpand,
}) => {
  if (fileMetadata.files == null) {
    return (
      <Dialog
        aria-labelledby="simple-Dialog-title"
        aria-describedby="simple-Dialog-description"
        fullWidth
        open={open}
        onClose={() => {
        }}
      >
        <div>
          <div id="Dialog-title">
            <div style={styles.header}>
              <span style={styles.iconsAndText}>
                <h2> {title} </h2>
                <h4> [{jobTitle}] </h4>
              </span>
            </div>            
            <hr />
            <Container>
              <p>
                Retrieving file list...
              </p>
              <BrandedLinearLoadingIndicator />
            </Container>
          </div>
        </div>
        <DialogActions>
          <Button variant="outlined" onClick={() => toggleExpand()}>
            Close
          </Button>
        </DialogActions>        
      </Dialog>
    )
  }
  return (
  <Dialog
    aria-labelledby="simple-Dialog-title"
    aria-describedby="simple-Dialog-description"
    fullWidth
    open={open}
    onClose={() => {
    }}
  >
    <div>
      <div id="Dialog-title">
        <div style={styles.header}>
          <span style={styles.iconsAndText}>
            <h2>
              {title}
            </h2>
            <h4>
              [
              {jobTitle}
              ]
            </h4>
          </span>
        </div>
        <hr />
        <WrappedDocumentList
          fileMetadata={fileMetadata}
        />
      </div>
    </div>
    <DialogActions>
      <Button variant="outlined" onClick={() => toggleExpand()}>
        Close
      </Button>
    </DialogActions>
  </Dialog>
)};

DocumentsModal.propTypes = {
  title: PropTypes.string.isRequired,
  jobTitle: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  toggleExpand: PropTypes.func.isRequired,
  fileMetadata: PropTypes.shape({
    files: PropTypes.arrayOf(PropTypes.object),
    fileListDownloadErrorMessage: PropTypes.string,
    fileListDownloading: PropTypes.bool,
    lcaId: PropTypes.string,
    pafId: PropTypes.string,
  }),
};

DocumentsModal.defaultProps = {
  fileMetadata: {
    files: [],
    fileListDownloadErrorMessage: null,
    fileListDownloading: false,
  },
};

export default DocumentsModal;
