import { ERROR_PROMPT_TRIGGER, ERROR_PROMPT_CLEAR } from '../actions';

/**
 * Returns a new state depending on the provided action.
 * @param {{
 *   errorMessage: string,
 *   errorPromptOpen: boolean,
 *   errorTime: number,
 * }} state The current state.
 * @param {{
 *   type: string,
 *   errorMessage: string,
 * }} action An object containing parameters and the action to perform.
 * @returns {{
 *   errorMessage: string,
 *   errorPromptOpen: boolean,
 *   errorTime: number,
 * }} The new state to use.
 */
const errorPrompt = (state = getDefaultState(), action) => {
  switch (action.type) {
    case ERROR_PROMPT_TRIGGER:
      return {
        errorPromptOpen: true,
        errorMessage: action.errorMessage,
        errorTime: Date.now(),
      };
    case ERROR_PROMPT_CLEAR:
      return getDefaultState();
    default:
      return state;
  }
};

/**
 * The default state for this reducer.
 * @returns {{
 *   errorMessage: string,
 *   errorPromptOpen: boolean,
 *   errorTime: number
 * }} A new instance of the default state.
 */
const getDefaultState = () => ({
  errorPromptOpen: false,
  errorMessage: '',
  errorTime: Date.now(),
});

export default errorPrompt;
