import {
  LCA_METADATA_DOWNLOAD_BEGIN,
  LCA_METADATA_DOWNLOAD_SUCCESS,
  LCA_METADATA_DOWNLOAD_ERROR,
  LCA_METADATA_ADD,
} from '../actions';

/**
 * Returns a new state depending on the provided action.
 * @param {{
 *   lcaId: string,
 *   files: Object[],
 *   fileListDownloading: boolean,
 *   fileListDownloadErrorMessage: string,
 * }[]} state The current state.
 * @param {{
 *   type: string,
 *   lcaId: string,
 *   fileList: Object[],
 *   errorMessage: string,
 * }} action An object containing parameters and the action to perform.
 * @returns {{
 *   lcaId: string,
 *   files: Object[],
 * }[]} The new state to apply.
 */
const lcaMetadata = (state = [], action) => {
  switch (action.type) {
    // Add a LCA to the list of known LCAs.
    case LCA_METADATA_ADD:
      if (state.find((lcaMeta) => lcaMeta.lcaId === action.lcaId)) {
        return state;
      }

      return [
        ...state,
        {
          lcaId: action.lcaId,
          files: null,
        },
      ];
    // Mark a LCA as having its metadata downloaded.
    case LCA_METADATA_DOWNLOAD_BEGIN:
      return state.map((lca) => {
        if (lca.lcaId === action.lcaId) {
          return {
            ...lca,
            fileListDownloading: true,
            fileListDownloadErrorMessage: null,
          };
        }
        return lca;
      });
    // Update a LCA entry with its metadata.
    case LCA_METADATA_DOWNLOAD_SUCCESS:
      return state.map((lca) => {
        if (lca.lcaId === action.lcaId) {
          return {
            ...lca,
            fileListDownloading: false,
            files: action.fileList,
          };
        }
        return lca;
      });
    // Updates a LCA as having failed to download its metadata.
    case LCA_METADATA_DOWNLOAD_ERROR:
      return state.map((lca) => {
        if (lca.lcaId === action.lcaId) {
          return {
            ...lca,
            fileListDownloading: false,
            files: [],
            fileListDownloadErrorMessage: action.errorMessage,
          };
        }
        return lca;
      });
    // Makes no changes to the state.
    default:
      return state;
  }
};

export default lcaMetadata;
