import { TableCell, withStyles } from '@material-ui/core';

/**
 * Gets style options to apply to the component.
 * @param {Object} theme Theme styles to apply.
 * @returns {{
 *  head: {
 *    backgroundColor: string,
 *    color: *,
 *    fontSize: number
 *  },
 *  body: {
 *    color: string,
 *    fontSize: number
 *  }
 * }}
 */
const style = (theme) => ({
  head: {
    backgroundColor: '#6B6B6B',
    color: theme.palette.common.white,
    fontSize: 18,
  },
  body: {
    fontSize: 14,
    color: '#6B6B6B',
  },
});

/**
 * A customized table cell for the grid.
 */
export default withStyles(style)(TableCell);
