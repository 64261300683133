import { combineReducers } from 'redux';
import clientData from './clientData';
import pafMetadata from './pafMetadata';
import fileDownload from './fileDownload';
import errorPrompt from './errorPrompt';
import paginationContext from './paginationContext';
import lcaMetadata from './lcaMetadata';

/**
 * Combines reducers into a single object.
 */
export default combineReducers({
  clientData,
  pafMetadata,
  lcaMetadata,
  fileDownload,
  errorPrompt,
  paginationContext,
});
