import { getFakeLcaFileList } from '../helpers/mockJsonData';
import RequestBuilder from '../helpers/requestBuilder';

/**
 * Formats company data from the API definition to the local definition.
 * @param lcaId {string} The ID fo the LCA associated with this data.
 * @param responseJson {Object} Company data object from API.
 * @return {{
 *   displayName: string,
 *   downloadUrl: string
 * }[]} A formatted company data object.
 */
const convertResponse = (lcaId, responseJson) => {
  // Left side is local name, right side is expected API name
  const lcaFileListDataMap = [
    ['displayName', 'Name'],
  ];

  const formattedLcaFileList = [];

  responseJson.forEach((file) => {
    const formattedFile = {};

    // Copy values based on the mapping, but only if property is defined.
    for (let index = 0; index < lcaFileListDataMap.length; index += 1) {
      if (file[lcaFileListDataMap[index][1]]) {
        formattedFile[lcaFileListDataMap[index][0]] = file[lcaFileListDataMap[index][1]];
      }
    }

    formattedFile.downloadUrl = RequestBuilder.buildLcaFileRequestUrl(
      isNaN(Number.parseInt(lcaId, 10)) ? file.id : lcaId,
      formattedFile.displayName,
      formattedFile.requiresAuth = false,
    );

    formattedLcaFileList.push(formattedFile);
  });

  return formattedLcaFileList;
};

/**
 * Removes characters from a string that are not permitted.
 * @param accessCode {string} A string of user input.
 * @return {string} A string with only permitted characters included.
 */
const sanitizeInput = (accessCode) => accessCode?.replace(/[^0-9a-z-]/gi, '');

/**
 * Handles processing for successful responses.
 * @param response {Response} A successful response.
 * @param lcaId {string} The ID of the LCA related to this data.
 * @param resolve {function(*=): *} Function to execute on success.
 * @param reject {function(*=): *} Function to execute on failure/error.
 */
const handleSuccess = (response, lcaId, resolve, reject) => {
  response.json().then((responseJson) => {
    if (responseJson) {
      resolve(convertResponse(lcaId, responseJson));
    }
  }).catch(() => reject('An error occurred while requesting the file list.'));
};

/**
 * Handles a request for the file list for a LCA.
 * @param accessCode {string} The access code to submit with the request.
 * @param lcaId {string} The ID of the LCA to request information for.
 * @param resolve {function(*=): *} Function to execute on success.
 * @param reject {function(*=): *} Function to execute on failure/error.
 * @return {undefined} This function does not return a value.
 */
const requestLcaFileList = (accessCode, lcaId, resolve, reject) => {
  const sanitizedCode = sanitizeInput(accessCode);

  if (sanitizedCode.indexOf('-') === 0) {
    getFakeLcaFileList(accessCode)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
    return;
  }

  const request = RequestBuilder.buildLcaFileListRequestObject(lcaId);

  fetch(request.url, request)
    .then((response) => {
      if (response.ok) {
        handleSuccess(response, lcaId, resolve, reject);
      } else {
        reject('An error occurred while requesting the file list.');
      }
    }).catch((error) => {
      reject(error.message);
    });
};

export default requestLcaFileList;
