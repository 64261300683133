/* eslint-disable no-console */

/**
 * A generic mock dataset.
 * @type {{
 *  companyName: string,
 *  pageIntro: string,
 *  jobListings: {
 *    fileId: string,
 *    lcaNumber: string,
 *    jobTitle: string,
 *    fileNumber: string,
 *    postingDate: string,
 *    downloadUrl: string,
 *  }[],
 *  pageIntroForNofListings: string,
 *  nofListings: {
 *    jobTitle: string,
 *    postingDate: string,
 *    fileId: string,
 *    downloadUrl: string,
 *  }[]
 * }}
 */
const fakeNormalJsonData = {
  companyName: 'Erickson Immigration Group',
  pageIntro: 'Attached please find current LCAs for the company',
  jobListings: [
    {
      fileId: '0',
      lcaNumber: 'T-123-1234-5678',
      jobTitle: 'Software Engineer',
      fileNumber: '1234567',
      postingDate: 'January 10, 2019',
      downloadUrl: `${window.location.origin}/img/eig-logo.png`,
    },
    {
      fileId: '1',
      lcaNumber: 'T-123-1234-5678',
      jobTitle: 'Product Manager',
      fileNumber: '1234567',
      postingDate: 'January 10, 2019',
      downloadUrl: `${window.location.origin}/img/eig-logo.png`,
    },
    {
      fileId: '2',
      lcaNumber: 'T-123-1234-5678',
      jobTitle: 'UX Designer',
      fileNumber: '1234567',
      postingDate: 'January 10, 2019',
      downloadUrl: `${window.location.origin}/img/eig-logo.png`,
    },
    {
      fileId: '3',
      lcaNumber: 'T-123-1234-5678',
      jobTitle: 'Marketing Manager',
      fileNumber: '1234567',
      postingDate: 'January 10, 2019',
      downloadUrl: `${window.location.origin}/img/eig-logo.png`,
    },
  ],
  pageIntroForNofListings: 'Attached please find current NOFs for the company',
  nofListings: [
    {
      jobTitle: 'Software Engineer',
      postingDate: 'January 10, 2019',
      fileId: '0',
      downloadUrl: `${window.location.origin}/img/eig-logo.png`,
    },
    {
      jobTitle: 'Product Manager',
      postingDate: 'January 10, 2019',
      fileId: '1',
      downloadUrl: `${window.location.origin}/img/eig-logo.png`,
    },
    {
      jobTitle: 'UX Designer',
      postingDate: 'January 10, 2019',
      fileId: '2',
      downloadUrl: `${window.location.origin}/img/eig-logo.png`,
    },
    {
      jobTitle: 'Marketing Manager',
      postingDate: 'January 10, 2019',
      fileId: '3',
      downloadUrl: `${window.location.origin}/img/eig-logo.png`,
    },
  ],
};

/**
 * A shell to add custom mock data to.
 * @type {{companyName: string}}
 */
const fakeShellJsonData = {
  companyName: 'Erickson Immigration Group',
};

/**
 * Extension to use for fake LCA files.
 * @type {string}
 */
const fakeLcaFileExtension = ".svg";

/**
 * A fake LCA file object.
 * @type {{displayName: string, downloadUrl: string}}
 */
const fakeLcaFile = {
  displayName: 'LCA Document',
  // Locally generated data
  downloadUrl: `${window.location.origin}/img/eig-logo.png`,
};

/**
 * A mock object representing an LCA entry.
 * @type {{
 *  fileNumber: string,
 *  jobTitle: string,
 *  downloadUrl: string,
 *  postingDate: string,
 *  lcaNumber: string,
 *  fileId: string
 * }}
 */
const fakeLcaEntryData = {
  fileId: '0',
  lcaNumber: 'T-123-1234-5678',
  jobTitle: 'Marketing Manager',
  fileNumber: '1234567',
  postingDate: '2019-10-17T06:53:42Z',
  // Locally generated data.
  downloadUrl: `${window.location.origin}/img/portal-header-min.jpg`,
};

/**
 * A mock object representing a PAF entry.
 * @type {{
 *  fileNumber: string,
 *  jobTitle: string,
 *  expiredDate: string,
 *  postingDate: string,
 *  lcaNumber: string,
 *  fileId: string
 * }}
 * @remark Expired dates currently us 'mm-dd-yyyy' format.
 */
const fakePafEntryData = {
  fileId: '0',
  lcaNumber: 'T-987-6543-2100',
  jobTitle: 'Junior Accountant',
  fileNumber: '8593892',
  postingDate: '2019-10-17T06:53:42Z',
  expiredDate: '2019-10-30',
};

/**
 * Extension to use for fake PAF files.
 * @type {string}
 */
const fakePafFileExtension = ".svg";

/**
 * A fake PAF file object.
 * @type {{displayName: string, downloadUrl: string}}
 */
const fakePafFile = {
  displayName: 'LCA Document',
  // Locally generated data
  downloadUrl: `${window.location.origin}/img/eig-logo.png`,
};

/**
 * A fake PAF intro message.
 * @type {string}
 */
const fakePafIntroText = "The company's handbook is listed below. Please see it attached. The company confirms it obliges with all posting guidelines outlined in 20 C.F.R. and 8 C.F.R.";

/**
 * A fake company memo object.
 * @type {{companyMemo: string}}
 */
const fakeCompanyMemo = {
  companyMemo: `${window.location.origin}/img/cropped-eigFavicon-1-32x32.png`,
};

/**
 * A mock object representing NOF data
 * * @type {{
 *  jobTitle: string,
 *  postingDate: string,
 *  fileId: string,
 *  downloadurl: string
 * }}
 */
const fakeNofEntryData = {
  jobTitle: 'Marketing Manager',
  postingDate: '2019-10-17T06:53:42Z',
  fileId: '0',
  // Locally generated data.
  downloadUrl: `${window.location.origin}/img/portal-header-min.jpg`,
};

/**
 * Gets an error object.
 * @returns {{errorMessage: string}} An error object.
 */
const buildErrorData = () => ({ errorMessage: 'Invalid access code.' });

/**
 * Generates a mock data object with a custom amount of entries.
 * @param {Number} entryNumber The number of entries to generate.
 * @return {{
 *   companyName: (string | null),
 *   loginLogo: (string | null),
 *   pageHeaderLogo: (string | null),
 *   helpContact: (string | null),
 *   titleBackground: (string | null),
 *   companyMemo: (string | null),
 *   companyMemo: (string | null),
 *   pafIntro: (string | null),
 *   jobListings: (Object[] | null),
 *   pafListings: (Object[] | null),
 *   nofListings: (Object[] | null)
 * }} A custom mock data object.
 */
const buildCustomSizeMockData = (entryNumber) => {
  const mockData = {};
  Object.assign(mockData, fakeShellJsonData, fakeCompanyMemo);

  mockData.jobListings = [];
  mockData.pafListings = [];
  mockData.nofListings = [];

  let entriesToGenerate = entryNumber;
  if (entryNumber > 100) {
    entriesToGenerate = 100;
  }

  mockData.jobListings = buildLcaList(entriesToGenerate);
  mockData.pafListings = buildPafList(entriesToGenerate);
  mockData.nofListings = buildNofList(entriesToGenerate);
  mockData.pafIntro = fakePafIntroText;

  console.log(mockData);

  return mockData;
};

/**
 * Generates a list of mock LCA data.
 * @param {Number} itemsToCreate The number of entries to create.
 * @returns {{
 *    lcaNumber: (string),
 *    jobTitle: (string),
 *    fileNumber: (string),
 *    postingDate: (string),
 *    fileId: (string),
 *    downloadUrl: (string)
 * }[]} A collection of formatted LCA data.
 */
const buildLcaList = (itemsToCreate) => {
  let entriesToGenerate = itemsToCreate;

  if (entriesToGenerate > 100) {
    entriesToGenerate = 100;
  }

  const returnList = [];

  for (let counter = 0; counter < entriesToGenerate; counter += 1) {
    const fakeModifiedEntry = { ...fakeLcaEntryData };
    fakeModifiedEntry.lcaNumber = fakeModifiedEntry.lcaNumber.concat('-', counter.toString());
    fakeModifiedEntry.fileId = counter;
    returnList.push(fakeModifiedEntry);
  }

  return returnList;
};

/**
 * Generates a list of PAF data.
 * @param {Number} itemsToCreate The number of entries to create.
 * @returns {{
 *    lcaNumber: (string | null),
 *    jobTitle: (string | null),
 *    fileNumber: (string | null),
 *    postingDate: (string | null),
 *    fileId: (string | null),
 *    expiredDate: (string | null)
 * }[]} A collection of formatted PAF data.
 */
const buildPafList = (itemsToCreate) => {
  let entriesToGenerate = itemsToCreate;

  if (entriesToGenerate > 100) {
    entriesToGenerate = 100;
  }

  const returnList = [];

  for (let counter = 0; counter < entriesToGenerate; counter += 1) {
    const fakeModifiedEntry = { ...fakePafEntryData };
    fakeModifiedEntry.fileId = counter;
    // Locally generated data
    fakeModifiedEntry.files = [];
    returnList.push(fakeModifiedEntry);
  }

  return returnList;
};

/**
 * Generates a list of mock NOF data.
 * @param {Number} itemsToCreate The number of entries to create.
 * @returns {{
  *    jobTitle: (string),
  *    postingDate: (string),
  *    fileId: (string),
  * }[]} A collection of formatted NOF data.
  */
const buildNofList = (itemsToCreate) => {
  let entriesToGenerate = itemsToCreate;

  if (entriesToGenerate > 100) {
    entriesToGenerate = 100;
  }

  const returnList = [];

  for (let counter = 0; counter < entriesToGenerate; counter += 1) {
    const fakeModifiedEntry = { ...fakeNofEntryData };
    fakeModifiedEntry.jobTitle = fakeModifiedEntry.jobTitle.concat('-', counter.toString());
    fakeModifiedEntry.fileId = counter;
    returnList.push(fakeModifiedEntry);
  }

  return returnList;
};

/**
 * Creates a mock object with customizable entry counts.
 * @param {String} parameters A string containing the parameters to use when building the data.
 * @returns {{
 *   companyName: (string),
 *   loginLogo: (string),
 *   pageHeaderLogo: (string | null),
 *   helpContact: (string),
 *   titleBackground: (string | null),
 *   companyMemo: (string | null),
 *   pafIntro: (string | null)
 * }} Mock company data.
 */
const buildModularMockData = (parameters) => {
  const mockData = {};
  Object.assign(mockData, fakeShellJsonData);

  const parameterList = parameters.split('-');

  parameterList.forEach((arg) => {
    switch (arg[0]) {
      case 'l':
        mockData.jobListings = buildLcaList(Number(arg.substring(1)));
        break;
      case 'p':
        mockData.pafListings = buildPafList(Number(arg.substring(1)));
        mockData.pafIntro = fakePafIntroText;
        break;
      case 'n':
        mockData.nofListings = buildNofList(Number(arg.substring(1)));
        mockData.pageIntroForNofListings = 'Attached please find current NOFs for the company';
        break;
      case 'm':
        Object.assign(mockData, fakeCompanyMemo);
        mockData.pafIntro = fakePafIntroText;
        break;
      default:
        break;
    }
  });

  console.log(mockData);

  return mockData;
};

/**
 * Takes in an access code that contains instructions for building mock data.
 * @param {String} accessCode The access code containing instructions for building mock data.
 * @returns {{
 *  companyName: string,
 *  jobListings: *[],
 *  pageIntro: string
*  }|{
 *  companyName: string,
 *  loginLogo: string,
 *  pageHeaderLogo: (string|null),
 *  helpContact: string,
 *  titleBackground: (string|null),
 *  companyMemo: (string|null),
 *  pafIntro: (string|null)
*  }|{
 *  errorMessage: string
*  }|{
 *  companyName: (string|null),
 *  loginLogo: (string|null),
 *  pageHeaderLogo: (string|null),
 *  helpContact: (string|null),
 *  titleBackground: (string|null),
 *  companyMemo: (string|null),
 *  pafIntro: (string|null),
 *  jobListings: (Object[]|null),
 *  pafListings: (Object[]|null),
 *  nofListings: (Object[]|null)
 * }} A mock data object.
 */
const processAccessCode = (accessCode) => {
  const parameterPrefix = accessCode.indexOf('-');
  if (parameterPrefix === -1) {
    return fakeNormalJsonData;
  }

  const parameters = accessCode.substring(parameterPrefix + 1);

  if (parameters[0] === 'c') {
    return buildCustomSizeMockData(Number(parameters.substring(1)));
  }

  if (parameters[0] === 'a') {
    return buildModularMockData(parameters.substring(1));
  }

  if (parameters[0] === 'i') {
    return buildErrorData();
  }

  return fakeNormalJsonData;
};

/**
 * Simulates a call for paf file info.
 * @param accessCode {string} The access code to submit with the request.
 * @return {Promise} A promise that will supply a JSON payload in string format.
 */
export const getFakePafFileList = (accessCode) => new Promise((resolve, reject) => {
  const parameterPrefix = accessCode.indexOf('-');
  if (parameterPrefix !== -1 && accessCode.includes('-pfe')) {
    reject(new Error('An error occurred when requesting the file list'));
    return;
  }

  console.log('Simulating paf file list request. Waiting to deliver mock data.');
  const fileList = [];

  setTimeout(() => {
    for (let counter = 0; counter < 5; counter += 1) {
      const temporaryPafFile = { ...fakePafFile };
      temporaryPafFile.displayName = temporaryPafFile.displayName.concat('-', counter, fakePafFileExtension);
      fileList.push(temporaryPafFile);
    }

    console.log('Delivering mock paf file list.');
    resolve(fileList);
  }, 1000);
});

/**
 * Simulates a call for lca file info.
 * @param accessCode {string} The access code to submit with the request.
 * @return {Promise} A promise that will supply a JSON payload in string format.
 */
export const getFakeLcaFileList = (accessCode) => new Promise((resolve, reject) => {
  const parameterPrefix = accessCode.indexOf('-');
  if (parameterPrefix !== -1 && accessCode.includes('-pfe')) {
    reject(new Error('An error occurred when requesting the file list'));
    return;
  }

  console.log('Simulating lca file list request. Waiting to deliver mock data.');
  const fileList = [];

  setTimeout(() => {
    for (let counter = 0; counter < 5; counter += 1) {
      const temporaryLcaFile = { ...fakeLcaFile };
      temporaryLcaFile.displayName = temporaryLcaFile.displayName.concat('-', counter, fakeLcaFileExtension);
      fileList.push(temporaryLcaFile);
    }

    console.log('Delivering mock lca file list.');
    resolve(fileList);
  }, 1000);
});

/**
 * Simulates the call for client data.
 * @param accessCode {string} The access code to submit with the request.
 * @return {Promise} A promise that will supply a JSON payload in string format.
 */
export const getFakeJson = (accessCode) => new Promise((resolve) => {
  console.log(`Simulating code: ${accessCode}`);
  console.log('Waiting to deliver mock client data.');
  const result = processAccessCode(accessCode);

  if (!result.errorMessage) {
    setTimeout(() => {
      console.log('Delivering mock client data.');
      resolve(processAccessCode(accessCode));
    }, 1000);
  } else {
    throw new Error(result.errorMessage);
  }
});
